import { useDispatch } from "src/store";
import { IconButton } from "@mui/material";
import { CustomTooltip } from "src/components/inputs";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
// Redux Toolkit
import { thunks } from "src/thunks/linesheet";

export const DownloadTemplateButton = () => {
  const dispatch = useDispatch();

  // Handle Download Template
  const handleDownloadTemplate = () => {
    dispatch(thunks.downloadLinesheetTemplate());
  };

  return (
    <>
      <CustomTooltip title="Download the blank linesheet template">
        <IconButton onClick={() => handleDownloadTemplate()}>
          <FileDownloadIcon style={{ color: "black" }} />
        </IconButton>
      </CustomTooltip>
    </>
  );
};
