import { Box } from "@mui/material";
import tmwLogo from "src/images/tmw_logo.png";

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: "center",
      backgroundColor: "background.paper",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      left: 0,
      p: 3,
      position: "fixed",
      top: 0,
      width: "100vw",
      zIndex: 1400,
    }}
  >
    <Box>
      <img
        style={{
          height: 300,
          width: 300,
        }}
        src={tmwLogo}
      />
    </Box>
  </Box>
);
