import { createSlice } from "@reduxjs/toolkit";

const purchaseOrdersState = {
  // Purchase Order (Single)
  purchaseOrder: {},

  purchaseOrderLoading: false,
  refreshPurchaseOrder: false,

  // Purchase Order History
  purchaseOrderHistory: {},

  purchaseOrderHistoryLoading: false,

  // Purchase Order Email History
  purchaseOrderEmailHistory: {},

  purchaseOrderEmailHistoryLoading: false,

  // Change Purchase Order Status
  changePurchaseOrderStatusInProgress: false,

  // Revert Purchase Order Status
  revertPurchaseOrderStatusInProgress: false,

  // Purchase Orders
  purchaseOrders: [],

  purchaseOrdersLoading: false,

  // Purchase Orders Filters Data
  purchaseOrdersFiltersData: {},

  purchaseOrdersFiltersDataLoading: false,

  // Purchase Order Items
  purchaseOrderItems: [],
  purchaseOrderItemsSizes: [],

  purchaseOrderItemsLoading: false,
  refreshPurchaseOrderItems: false,

  // Purchase Order Items Update
  updatePurchaseOrderItemsLoading: false,

  // Purchase Order Item
  purchaseOrderItem: {},

  purchaseOrderItemLoading: false,

  // Cancel Purchase Order Item
  cancelPurchaseOrderItemLoading: false,

  // Reactivate Purchase Order Item
  reactivatePurchaseOrderItemLoading: false,
};

const reducers = {
  // Purchase Orders List
  purchaseOrderListLoading(state) {
    if (!state.purchaseOrdersLoading) {
      state.purchaseOrdersLoading = true;
    }
  },
  purchaseOrderListReceived(state, action) {
    if (state.purchaseOrdersLoading) {
      state.purchaseOrdersLoading = false;

      // Parse Purchase Orders from API to match the table Column Definition
      const purchaseOrdersRows = action.payload.map((item) => {
        return {
          ...item,
          brand: item.brand?.name,
        };
      });

      state.purchaseOrders = purchaseOrdersRows;
    }
  },

  // Purchase Order (Single)
  purchaseOrderLoading(state) {
    if (!state.purchaseOrderLoading) {
      state.purchaseOrderLoading = true;
    }
  },
  purchaseOrderReceived(state, action) {
    if (state.purchaseOrderLoading) {
      state.purchaseOrderLoading = false;
      state.purchaseOrder = action.payload;
    }
  },

  // Purchase Order History
  purchaseOrderHistoryLoading(state) {
    if (!state.purchaseOrderHistoryLoading) {
      state.purchaseOrderHistoryLoading = true;
    }
  },
  purchaseOrderHistoryReceived(state, action) {
    if (state.purchaseOrderHistoryLoading) {
      state.purchaseOrderHistoryLoading = false;
      state.purchaseOrderHistory = action.payload;
    }
  },

  // Purchase Order Email History
  purchaseOrderEmailHistoryLoading(state) {
    if (!state.purchaseOrderEmailHistoryLoading) {
      state.purchaseOrderEmailHistoryLoading = true;
    }
  },
  purchaseOrderEmailHistoryReceived(state, action) {
    if (state.purchaseOrderEmailHistoryLoading) {
      state.purchaseOrderEmailHistoryLoading = false;
      state.purchaseOrderEmailHistory = action.payload;
    }
  },

  // Purchase Orders Filters Data
  purchaseOrdersFiltersDataLoading(state) {
    if (!state.purchaseOrdersFiltersDataLoading) {
      state.purchaseOrdersFiltersDataLoading = true;
    }
  },
  purchaseOrdersFiltersDataReceived(state, action) {
    if (state.purchaseOrdersFiltersDataLoading) {
      state.purchaseOrdersFiltersDataLoading = false;
      state.purchaseOrdersFiltersData = action.payload;
    }
  },

  // Purchase Order Items List
  purchaseOrderItemsListLoading(state) {
    if (!state.purchaseOrderItemsLoading) {
      state.purchaseOrderItemsLoading = true;
    }
  },
  purchaseOrderItemsListReceived(state, action) {
    if (state.purchaseOrderItemsLoading) {
      state.purchaseOrderItemsLoading = false;

      // Parse Purchase Orders Items from API to match the table Column Definition
      const purchaseOrderItemsRows = action.payload.purchaseOrderItems.map(
        (item) => {
          return {
            ...item,
            brand: item.brand?.name,
          };
        }
      );

      state.purchaseOrderItems = purchaseOrderItemsRows;
      state.purchaseOrderItemsSizes = action.payload.purchaseOrderItemsSizes;
    }
  },

  // Purchase Order Items List Reset Refresh
  purchaseOrderItemsListResetRefresh(state) {
    state.refreshPurchaseOrderItems = false;
  },

  // Purchase Order Item (Single)
  purchaseOrderItemLoading(state) {
    if (!state.purchaseOrderItemLoading) {
      state.purchaseOrderItemLoading = true;
    }
  },
  purchaseOrderItemReceived(state, action) {
    if (state.purchaseOrderItemLoading) {
      state.purchaseOrderItemLoading = false;
      state.purchaseOrderItem = action.payload;
    }
  },

  // Purchase Order Items - Edit purchase order (row)
  editPurchaseOrderRow(state, action) {
    state.purchaseOrderItems = [...state.purchaseOrderItems].map((row) => {
      if (row.id === action.payload.id) {
        return action.payload;
      }

      return row;
    });
  },

  // Purchase Order Items Update --Table Bulk--
  purchaseOrdersItemsUpdateLoading(state) {
    if (!state.updatePurchaseOrderItemsLoading) {
      state.updatePurchaseOrderItemsLoading = true;
    }
  },
  purchaseOrdersItemsUpdateLoadingReceived(state, action) {
    if (state.updatePurchaseOrderItemsLoading) {
      state.updatePurchaseOrderItemsLoading = false;
      state.refreshPurchaseOrderItems = true;
    }
  },

  // Cancel Purchase Order Item
  cancelPurchaseOrderItemLoading(state) {
    if (!state.cancelPurchaseOrderItemLoading) {
      state.cancelPurchaseOrderItemLoading = true;
    }
  },
  cancelPurchaseOrderItemReceived(state, action) {
    if (state.cancelPurchaseOrderItemLoading) {
      state.cancelPurchaseOrderItemLoading = false;
      state.refreshPurchaseOrderItems = true;
    }
  },

  // Reactivate Purchase Order Item
  reactivatePurchaseOrderItemLoading(state) {
    if (!state.reactivatePurchaseOrderItemLoading) {
      state.reactivatePurchaseOrderItemLoading = true;
    }
  },
  reactivatePurchaseOrderItemReceived(state, action) {
    if (state.reactivatePurchaseOrderItemLoading) {
      state.reactivatePurchaseOrderItemLoading = false;
      state.refreshPurchaseOrderItems = true;
    }
  },

  // Change Purchase Order Status
  changePurchaseOrderStatusLoading(state) {
    if (!state.changePurchaseOrderStatusInProgress) {
      state.changePurchaseOrderStatusInProgress = true;
    }
  },
  changePurchaseOrderStatusReceived(state, action) {
    if (state.changePurchaseOrderStatusInProgress) {
      state.changePurchaseOrderStatusInProgress = false;
      state.refreshPurchaseOrder = true;
    }
  },
  resetPurchaseOrderRefresh(state, action) {
    state.refreshPurchaseOrder = false;
  },

  // Revert Purchase Order Status
  revertPurchaseOrderStatusLoading(state) {
    if (!state.revertPurchaseOrderStatusInProgress) {
      state.revertPurchaseOrderStatusInProgress = true;
    }
  },
  revertPurchaseOrderStatusReceived(state, action) {
    if (state.revertPurchaseOrderStatusInProgress) {
      state.revertPurchaseOrderStatusInProgress = false;
      state.refreshPurchaseOrder = true;
    }
  },
};

export const slice = createSlice({
  name: "purchase-orders",
  initialState: purchaseOrdersState,
  reducers,
});

export const { reducer } = slice;
