import PropTypes from "prop-types";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { Box, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { TenantPopover } from "./tenant-popover";
import deployEnvironment from "src/deployEnvironment";

const subTitle =
  deployEnvironment === "http://localhost:1337"
    ? "Development"
    : deployEnvironment === "https://sandbox.tomorrowltd.com"
    ? "Sandbox"
    : deployEnvironment === "https://sandbox1.tomorrowltd.com"
    ? "Sandbox1"
    : "";

// const tenants = ['Devias', 'Acme Corp'];

export const TenantSwitch = (props) => {
  const popover = usePopover();

  return (
    subTitle && (
      <>
        <Stack
          alignItems="center"
          style={{ marginLeft: "25px" }}
          direction="row"
          spacing={2}
          {...props}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography color="inherit" variant="h6">
              Tomorrow Portal
            </Typography>
            <Typography color="neutral.400" variant="body2">
              {subTitle}
            </Typography>
          </Box>
          {/* <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <SvgIcon sx={{ fontSize: 16 }}>
            <ChevronDownIcon />
          </SvgIcon>
        </IconButton> */}
        </Stack>
        {/* <TenantPopover
        anchorEl={popover.anchorRef.current}
        onChange={popover.handleClose}
        onClose={popover.handleClose}
        open={popover.open}
        tenants={tenants}
      /> */}
      </>
    )
  );
};

TenantSwitch.propTypes = {
  sx: PropTypes.object,
};
