import { priceManagementApi } from "src/api/price-management";
import { linesheetApi } from "src/api/linesheet";
import { slice } from "src/slices/price-management";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Linesheets
const getApprovedLinesheetList = () => async (dispatch) => {
  dispatch(slice.actions.approvedLinesheetListLoading());
  const response = await priceManagementApi.getApprovedLinesheetList();
  dispatch(slice.actions.approvedLinesheetListReceived(response));
};

// Calculate Prices
const calculatePrices = createAsyncThunk(
  "priceManagement/calculatePrices",
  async (linesheetId, { rejectWithValue }) => {
    try {
      const response = await priceManagementApi.calculatePrices(linesheetId);
      return response.data;
    } catch (error) {
      throw rejectWithValue(error); // Return error payload
    }
  }
);

// Reset Price Management Linesheets List
const resetLinesheetsListRefresh = () => (dispatch) => {
  dispatch(slice.actions.resetPriceManagementLinesheetListRefresh());
};

// Reset Calculate Prices Errors
const resetCalculatePricesErrors = () => (dispatch) => {
  dispatch(slice.actions.resetCalculatePricesErrors());
};

// Reset Linesheet Items Refresh
const resetLinesheetItemsRefresh = () => (dispatch) => {
  dispatch(slice.actions.resetLinesheetItemsRefresh());
};

// Sync Linesheet To Netsuite (Price Management Table)
const syncLinesheetToNetsuitePriceManagementTablePage =
  (linesheetId, data) => async (dispatch) => {
    dispatch(slice.actions.syncLinesheetToNetsuiteLoading());
    const response = await linesheetApi.syncLinesheetToNetsuite(
      linesheetId,
      data
    );
    dispatch(slice.actions.syncLinesheetToNetsuiteReceived(response));
  };

const uploadPriceManagementItemsPricesFromExcel = createAsyncThunk(
  "linesheet/uploadPriceManagementItemsPricesFromExcel",
  async ({ linesheetId, formData }, { rejectWithValue }) => {
    try {
      const response =
        await linesheetApi.uploadPriceManagementItemsPricesFromExcel(
          linesheetId,
          formData
        );
      return response;
    } catch (error) {
      throw rejectWithValue(error); // Return error payload
    }
  }
);

const uploadPriceManagementItemsErrorReset = () => async (dispatch) => {
  dispatch(slice.actions.priceManagementItemsImportErrorReset());
};

export const priceManagementThunks = {
  // Linesheets
  getApprovedLinesheetList,
  resetLinesheetsListRefresh,

  // Calculate Prices
  calculatePrices,
  resetCalculatePricesErrors,
  resetLinesheetItemsRefresh,

  // Netsuite
  syncLinesheetToNetsuitePriceManagementTablePage,

  // Price Management Items
  uploadPriceManagementItemsPricesFromExcel,
  uploadPriceManagementItemsErrorReset,
};
