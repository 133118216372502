import PropTypes from "prop-types";
import { useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { NarrowSideNav } from "./narrow-side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";

const SIDE_NAV_WIDTH = 280;
const NARROW_SIDE_NAV_WIDTH = 60;

const VerticalLayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const VerticalLayout = (props) => {
  const { children, sections, navColor, drawerTransition } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();

  const VerticalLayoutRoot = styled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    maxWidth: "100%",
    [theme.breakpoints.up("lg")]: {
      paddingLeft: props.drawerOpen ? SIDE_NAV_WIDTH : NARROW_SIDE_NAV_WIDTH,
    },
  }));

  return (
    <>
      <TopNav
        onMobileNavOpen={mobileNav.handleOpen}
        navWidth={props.drawerOpen ? SIDE_NAV_WIDTH : NARROW_SIDE_NAV_WIDTH}
      />
      {lgUp && (
        <>
          {props.drawerOpen ? (
            <SideNav
              color={navColor}
              sections={sections}
              navWidth={SIDE_NAV_WIDTH}
              setOpen={props.handleClose}
              drawerTransition={drawerTransition}
            />
          ) : (
            <NarrowSideNav
              color={navColor}
              sections={sections}
              navWidth={NARROW_SIDE_NAV_WIDTH}
              setOpen={props.handleOpen}
            />
          )}
        </>
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot>
        <VerticalLayoutContainer>{children}</VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
