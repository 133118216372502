import { eTafApi } from "src/api/e-taf";
import { slice } from "src/slices/e-taf";
import { paths } from "src/paths";
import { wait } from "src/utils/wait";

const getCompanyData = (companyName) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCompanyDataLoading(true));
    dispatch(slice.actions.setCurrentCompanyName(companyName));

    const response = await eTafApi.getCompanyData(companyName);

    dispatch(slice.actions.setCompanyData(response));
    dispatch(slice.actions.setCompanyDataLoading(false));
  } catch (err) {
    // In case of an error in NetSuite fallback to fetch data from Strapi
    dispatch(slice.actions.setCompanyDataLoading(true));
    const response = await eTafApi.getCompanyDataFromStrapi(companyName);
    dispatch(slice.actions.setCompanyData(response));
    dispatch(slice.actions.setDataIsFromStrapi(true));
    dispatch(slice.actions.setCompanyDataLoading(false));
  }
};

const getCompanyDataFromStrapi = (companyName) => async (dispatch) => {
  try {
    const response = await eTafApi.getCompanyDataFromStrapi(companyName);

    dispatch(slice.actions.setCompanyDataFromStrapi(response));
  } catch (err) {
    console.log(err);
  }
};

const getCompanyHash = (companyName) => async (dispatch) => {
  try {
    const response = await eTafApi.getCompanyHash(companyName);

    dispatch(slice.actions.setCompanyHash(response));
  } catch (err) {
    console.log(err);
  }
};

const verifyCompanyHash = (userHash, companyHash) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCompanyDataLoading(true));
    const response = await eTafApi.verifyCompanyHash(userHash, companyHash);

    if (response.company) {
      dispatch(slice.actions.setCurrentCompanyName(response.company.name));
      dispatch(slice.actions.setCompanyData(response.company));
    }
    dispatch(slice.actions.setCompanyDataLoading(false));
  } catch (err) {
    dispatch(slice.actions.setCompanyDataLoading(false));
    console.log(err);
  }
};

const getCompanyName = (userHash, companyHash) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCompanyDataLoading(true));
    const response = await eTafApi.getCompanyName(userHash, companyHash);

    if (response?.association) {
      dispatch(
        slice.actions.setAssociationInfo({
          open: true,
          companyName: response?.companyName,
          temporaryId: companyHash,
        })
      );
    }

    if (response?.company?.name) {
      dispatch(slice.actions.setCurrentCompanyName(response.company.name));
    } else {
      dispatch(slice.actions.setCompanyData({}));
      dispatch(slice.actions.setCurrentCompanyName(null));
    }

    dispatch(slice.actions.setCompanyDataLoading(false));
  } catch (err) {
    console.log("here");
    dispatch(slice.actions.setCurrentCompanyName(null));
    console.log(err);
  }
};

const submitCompanyData =
  (
    userHash,
    companyName,
    data,
    companyHash,
    customerId,
    isAdmin,
    newCustomer,
    onlyModifiedAddressBook
  ) =>
  async (dispatch) => {
    try {
      dispatch(slice.actions.setDataSubmitted(true));
      dispatch(slice.actions.setCompanyDataSubmitting(true));

      await eTafApi.submitCompanyData(
        userHash,
        companyName,
        data,
        companyHash,
        isAdmin,
        newCustomer,
        onlyModifiedAddressBook
      );

      dispatch(slice.actions.setCompanyDataSubmitting(false));

      dispatch(thunks.getEtafData(customerId));
      window.location.href = paths.dashboard.clientManagement.companies.index;
    } catch (err) {
      dispatch(slice.actions.setSubmitError(true));
      dispatch(slice.actions.setCompanyDataSubmitting(false));
    }
  };

const getCompanyReferences = (userHash, companyName) => async (dispatch) => {
  try {
    const response = await eTafApi.getCompanyReferences(userHash, companyName);

    dispatch(slice.actions.setAllCountries(response.data.countries));
  } catch (err) {
    console.log(err);
  }
};

const getProvinces = (userHash, country) => async (dispatch) => {
  try {
    const response = await eTafApi.getProvinces(userHash, country);

    dispatch(slice.actions.setProvinces(response.data.provinces));
  } catch (err) {
    console.log(err);
  }
};

const getCustomers = () => async (dispatch) => {
  try {
    dispatch(slice.actions.setCustomersLoading(true));
    const response = await eTafApi.getEtafCustomers();

    dispatch(slice.actions.setCustomers(response));
    dispatch(slice.actions.setCustomersLoading(false));
  } catch (err) {
    console.log(err);
  }
};

const getEtafData = (customerId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setETafData({}));
    dispatch(slice.actions.setETafDataLoading(true));
    const response = await eTafApi.getEtafData(customerId);

    if (!response?.id) {
      dispatch(thunks.getContacts());
    }

    dispatch(slice.actions.setETafData(response));
    dispatch(slice.actions.setETafDataLoading(false));
  } catch (err) {
    dispatch(thunks.getContacts());
    dispatch(slice.actions.setETafData({}));
    dispatch(slice.actions.setETafDataLoading(false));
  }
};

const approveETaf =
  (userHash, customerId, data, companyName, companyHash, approvingETaf) =>
  async (dispatch) => {
    try {
      dispatch(
        slice.actions.setApprovingETaf([...approvingETaf, Number(customerId)])
      );

      // Saving data to Strapi
      await eTafApi.submitCompanyData(
        userHash,
        companyName,
        data,
        companyHash,
        true
      );

      setTimeout(() => {
        dispatch(thunks.getCustomers());
      }, 1000);

      // Sending data to NS
      await eTafApi.approveETaf(userHash, customerId);

      dispatch(
        slice.actions.setApprovingETaf(
          approvingETaf.filter(
            (_eTafId) => Number(_eTafId) !== Number(customerId)
          )
        )
      );
      dispatch(slice.actions.setApprovingETafModalVisible(false));
      dispatch(thunks.getCustomers());
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

const rejectEtaf = (userHash, customerId) => async (dispatch) => {
  try {
    // Set eTaf rejected in Strapi
    await eTafApi.rejectEtaf(userHash, customerId);

    window.location.reload();
  } catch (err) {
    console.log(err);
  }
};

const getContact = (contactId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setCurrentContactDetails(null));

    const response = await eTafApi.getContact(contactId);

    dispatch(slice.actions.setCurrentContactDetails(response));
  } catch (err) {
    console.log(err);
  }
};

const getContacts = () => async (dispatch) => {
  try {
    dispatch(slice.actions.setContactsLoading(true));
    const response = await eTafApi.getContacts();

    dispatch(slice.actions.setContacts(response.contacts));
    dispatch(
      slice.actions.setBulkEmailsBackgroundTaskStatus(
        response.bulkEmailsBackgroundTaskStatus
      )
    );
    dispatch(slice.actions.setContactsLoading(false));
  } catch (err) {
    console.log(err);
  }
};

const getContactsOfCompany = (companyId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setContactsOfCompany([]));

    const response = await eTafApi.getContactsOfCompany(companyId);

    dispatch(slice.actions.setContactsOfCompany(response));
  } catch (err) {
    console.log(err);
  }
};

const sendInitialEmailToContact = (email, isNewContact) => async (dispatch) => {
  try {
    await eTafApi.sendInitialEmailToContact(email, isNewContact);
  } catch (err) {
    console.log(err);
  }
};

const checkVatAvailability =
  (vatCode, customerId, contactId) => async (dispatch) => {
    try {
      const result = await eTafApi.checkVatAvailability(
        vatCode,
        customerId,
        contactId
      );

      if (result?.name) {
        dispatch(slice.actions.setVatAvailabilityError(true));
        dispatch(slice.actions.setExistingVAT(result));
      } else {
        dispatch(slice.actions.setVatAvailabilityError(false));
        dispatch(slice.actions.setExistingVAT(null));
      }
    } catch (err) {
      console.log(err);
    }
  };

const getAddressPredictions = (address) => async (dispatch) => {
  try {
    const result = await eTafApi.getAddressPredictions(address);

    if (result?.predictions.length) {
      dispatch(slice.actions.setInputAddress(result.inputAddress));
      dispatch(slice.actions.setAddressPredictions(result.predictions));
    }
  } catch (err) {
    console.log(err);
  }
};

const getAddressData = (address) => async (dispatch) => {
  try {
    const result = await eTafApi.getAddressData(address);

    if (result) {
      dispatch(slice.actions.setAddressData(result));
    }
  } catch (err) {
    console.log(err);
  }
};

const sendAssociationRequest =
  (userId, companyId, temporaryId) => async (dispatch) => {
    try {
      const result = await eTafApi.sendAssociationRequest(
        userId,
        companyId,
        temporaryId
      );

      dispatch(
        slice.actions.setAssociationInfo({
          open: true,
          companyName: result?.companyName,
          temporaryId,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

const getAssociationRequests = (companyId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAssociationRequestsOfCompany([]));

    const response = await eTafApi.getAssociationRequests(companyId);

    dispatch(slice.actions.setAssociationRequestsOfCompany(response));
  } catch (err) {
    console.log(err);
  }
};

const getAssociationRequestsOfContact = (contactId) => async (dispatch) => {
  try {
    dispatch(slice.actions.setAssociationRequestsOfContact([]));

    const response = await eTafApi.getAssociationRequestsOfContact(contactId);

    dispatch(slice.actions.setAssociationRequestsOfContact(response));
  } catch (err) {
    console.log(err);
  }
};

const getAllAssociationRequests = () => async (dispatch) => {
  try {
    const response = await eTafApi.getAllAssociationRequests();

    dispatch(slice.actions.setAllAssociationRequests(response));
  } catch (err) {
    console.log(err);
  }
};

const confirmAssociationRequest =
  (companyId, contactId) => async (dispatch) => {
    try {
      await eTafApi.confirmAssociationRequest(companyId, contactId);

      dispatch(thunks.getContactsOfCompany(companyId));
      dispatch(thunks.getAssociationRequests(companyId));
    } catch (err) {
      console.log(err);
    }
  };

const confirmAssociationRequestOfContact =
  (companyId, contactId) => async (dispatch) => {
    try {
      await eTafApi.confirmAssociationRequest(companyId, contactId);

      dispatch(thunks.getContact(contactId));
      dispatch(thunks.getAssociationRequestsOfContact(contactId));
    } catch (err) {
      console.log(err);
    }
  };

const removeAssociationRequest = (companyId, contactId) => async (dispatch) => {
  try {
    await eTafApi.removeAssociationRequest(companyId, contactId);

    dispatch(thunks.getAssociationRequests(companyId));
  } catch (err) {
    console.log(err);
  }
};

const removeAssociationRequestOfContact =
  (companyId, contactId) => async (dispatch) => {
    try {
      await eTafApi.removeAssociationRequest(companyId, contactId);

      dispatch(thunks.getAssociationRequestsOfContact(contactId));
    } catch (err) {
      console.log(err);
    }
  };

const sendBulkInitialEmailsToContacts = () => async (dispatch) => {
  try {
    await eTafApi.sendBulkInitialEmailsToContacts();
  } catch (err) {
    console.log(err);
  }
};

export const thunks = {
  getCompanyData,
  getCompanyDataFromStrapi,
  getCompanyHash,
  verifyCompanyHash,
  submitCompanyData,
  getCompanyName,
  getCompanyReferences,
  getProvinces,
  getCustomers,
  getEtafData,
  approveETaf,
  rejectEtaf,
  getContact,
  getContacts,
  getContactsOfCompany,
  sendInitialEmailToContact,
  checkVatAvailability,
  getAddressPredictions,
  getAddressData,
  sendAssociationRequest,
  getAssociationRequests,
  getAssociationRequestsOfContact,
  getAllAssociationRequests,
  confirmAssociationRequest,
  confirmAssociationRequestOfContact,
  removeAssociationRequest,
  removeAssociationRequestOfContact,
  sendBulkInitialEmailsToContacts,
};
