import { useRef, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Paper,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CustomButton, CustomTypography } from "src/components/inputs";
import tmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon.png";

export const LogoutConfirmModal = (props) => {
  const modalRef = useRef(null);
  const isOpen = props?.isOpen;

  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (isOpen) {
      modalRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [isOpen]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backdropFilter: !mdUp ? "blur(5px)" : "",
        zIndex: 1100,
      }}
    >
      <Box
        ref={modalRef}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: !mdUp ? "translate(-50%, -50%)" : "translate(-50%, 50%)",
          zIndex: 1100,
        }}
      >
        <Container maxWidth="sm">
          <Stack
            elevation={12}
            style={{
              background: "white",
              borderRadius: "25px",
              border: "1px solid #E6E6E6",
            }}
          >
            <Stack
              style={{
                height: "40px",
                background: "red",
                borderRadius: "25px 25px 0 0",
              }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                display: "flex",
                alignItems: "center",
                p: 3,
              }}
            >
              <img
                style={{ width: "40px", height: "40px" }}
                src={tmwWarningIcon}
              />
              <div
                style={{
                  marginRight: !mdUp ? "50px" : "",
                }}
              >
                <CustomTypography message={props?.message} />
              </div>
            </Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                pb: 3,
                px: 3,
                gap: 2,
              }}
            >
              <CustomButton
                redButton
                smallButton
                label={props?.cancelButtonLabel}
                color="inherit"
                sx={{ mr: 2 }}
                onClick={() => props?.cancelButton()}
              />
              <CustomButton
                filled
                redButton
                smallButton
                label={props?.submitButtonLabel}
                sx={{
                  backgroundColor: props?.successButtonBackgroundColor,
                  "&:hover": {
                    backgroundColor: props?.successButtonBackgroundColorHover,
                  },
                }}
                style={{
                  width: "100px",
                }}
                variant="contained"
                onClick={() => props?.submitButton()}
                disabled={props.isLoading ? true : false}
                loader={
                  props?.isLoading && (
                    <CircularProgress
                      size={20}
                      color="inherit"
                      style={{ marginLeft: "5px" }}
                    />
                  )
                }
              />
            </Box>
          </Stack>
        </Container>
      </Box>
    </div>
  );
};
