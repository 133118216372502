import axios from "axios";
const deployEnvironment = require("../../deployEnvironment.js");

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class PriceManagementApi {
  // Linesheets
  async getApprovedLinesheetList() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetsResponse = await axios.get(
      `${API}/api/linesheets/list/price-management`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheets = getLinesheetsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheets) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheets);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async calculatePrices(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    try {
      const calculatePricesResponse = await axios.get(
        `${API}/api/linesheet/${linesheetId}/calculate-prices`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return calculatePricesResponse.data;
    } catch (err) {
      throw err.response.data;
    }
  }
}

export const priceManagementApi = new PriceManagementApi();
