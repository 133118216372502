import { createSlice } from "@reduxjs/toolkit";
import { priceManagementThunks } from "src/thunks/price-management";

const priceManagementState = {
  // Linesheet
  linesheets: [],

  // Linesheets
  refreshPriceManagementLinesheetList: false,

  // Linesheet Loading/Redirect
  linesheetsLoading: false,

  // Linesheet Items - Calculate Prices -
  calculatePricesErrors: null,

  refreshLinesheetItems: false,
  calculatePricesLoading: false,

  // Sync Linesheet To Netsuite
  syncLinesheetToNetsuiteLoading: false,

  // Price Management Items Import Excel
  priceManagementItemsPricesExcelImportLoading: false,
  importPriceManagementExcelPricesError: null,
};

const reducers = {
  // Linesheet List
  approvedLinesheetListLoading(state) {
    if (!state.linesheetsLoading) {
      state.linesheetsLoading = true;
    }
  },
  approvedLinesheetListReceived(state, action) {
    if (state.linesheetsLoading) {
      state.linesheetsLoading = false;
      state.linesheets = action.payload;
    }
  },

  // Reset Refresh Price Management Linesheet List
  resetPriceManagementLinesheetListRefresh(state) {
    state.refreshPriceManagementLinesheetList = false;
  },

  // Reset Calculate Prices Errors
  resetCalculatePricesErrors(state) {
    state.calculatePricesErrors = null;
  },

  // Reset Refresh Linesheet Items after Calculate Prices
  resetLinesheetItemsRefresh(state) {
    state.refreshLinesheetItems = false;
  },

  // Sync Linesheet To Netsuite (Price Management Table Page)
  syncLinesheetToNetsuiteLoading(state) {
    if (!state.syncLinesheetToNetsuiteLoading) {
      state.syncLinesheetToNetsuiteLoading = true;
    }
  },

  syncLinesheetToNetsuiteReceived(state, action) {
    if (state.syncLinesheetToNetsuiteLoading) {
      state.syncLinesheetToNetsuiteLoading = false;
      state.refreshPriceManagementLinesheetList = true;
    }
  },

  // Reset Upload Price Management Prices Errors
  priceManagementItemsImportErrorReset(state) {
    state.importPriceManagementExcelPricesError = null;
  },
};

const extraReducers = {
  // Calculate Prices
  [priceManagementThunks.calculatePrices.pending]: (state, action) => {
    state.calculatePricesLoading = true;
  },
  [priceManagementThunks.calculatePrices.fulfilled]: (state, action) => {
    state.calculatePricesLoading = false;
    state.refreshLinesheetItems = true;
  },
  [priceManagementThunks.calculatePrices.rejected]: (state, action) => {
    state.calculatePricesLoading = false;
    state.calculatePricesErrors = action.payload.error.details;
  },
  // Upload Price Management Items Prices from Excel
  [priceManagementThunks.uploadPriceManagementItemsPricesFromExcel.pending]: (
    state,
    action
  ) => {
    state.priceManagementItemsPricesExcelImportLoading = true;
  },
  [priceManagementThunks.uploadPriceManagementItemsPricesFromExcel.fulfilled]: (
    state,
    action
  ) => {
    state.priceManagementItemsPricesExcelImportLoading = false;
    state.refreshLinesheetItems = true;
  },
  [priceManagementThunks.uploadPriceManagementItemsPricesFromExcel.rejected]: (
    state,
    action
  ) => {
    state.priceManagementItemsPricesExcelImportLoading = false;
    state.importPriceManagementExcelPricesError = action.payload.error.details;
  },
};

export const slice = createSlice({
  name: "price-management",
  initialState: priceManagementState,
  reducers,
  extraReducers,
});

export const { reducer } = slice;
