import PropTypes from "prop-types";
import Menu01Icon from "@untitled-ui/icons-react/build/esm/Menu01";
import { Box, IconButton, Stack, SvgIcon, useMediaQuery } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { AccountButton } from "../account-button";
import { HelpButton } from "../help-button";
import { TenantSwitch } from "../tenant-switch";
import tmwLogo from "src/images/tmw_logo.png";

const TOP_NAV_HEIGHT = 64;

export const TopNav = (props) => {
  const { onMobileNavOpen, navWidth, ...other } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: "blur(6px)",
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: "sticky",
        left: {
          lg: `${navWidth}px`,
        },
        top: 0,
        width: {
          lg: `calc(100% - ${navWidth}px)`,
        },
        zIndex: (theme) => theme.zIndex.appBar,
      }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2,
          mt: 5,
        }}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Box
            sx={{
              overflow: "hidden",
              display: "inline-flex",
              height: 70,
              width: 150,
            }}
          >
            <img style={{ margin: "-25% 0" }} src={tmwLogo} />
          </Box>
          <TenantSwitch />
        </Stack>
        {/* <Stack alignItems="center" direction="row" spacing={2}>
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack> */}
        <Stack alignItems="center" direction="row" spacing={2}>
          {/* <LanguageSwitch />
          <NotificationsButton /> */}
          <HelpButton />
          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func,
};
