import HelpOutlineIcon from "src/icons/tmw-ui/Tomorrow-Portal-Assistance-Symbol.png";
import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { HelpModal } from "./help-modal/HelpModal";
import { CustomTooltip } from "src/components/inputs";

export const HelpButton = () => {
  const popover = usePopover();

  return (
    <>
      <CustomTooltip title="Contact us">
        <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
          <img style={{ width: "22px" }} src={HelpOutlineIcon} />
        </IconButton>
      </CustomTooltip>
      <HelpModal
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
      />
    </>
  );
};
