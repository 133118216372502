import axios from "axios";
import deployEnvironment from "src/deployEnvironment";

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class EmailServiceApi {
  async sendQuestionEmail(user, question) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getAssociationDetails = () => {
      const groupName = user?.group?.groupName;

      if (
        groupName === "Brand" ||
        groupName === "Customer" ||
        groupName === "Tomorrow Crew"
      )
        return groupName;
      return "";
    };

    await axios.post(
      `${API}/api/send-email-question`,
      {
        email: user?.email,
        firstName: user?.firstName,
        brand: user?.brand,
        association: getAssociationDetails(),
        category: question.category,
        linesheet: question.linesheet,
        subject: question.subject,
        message: question.message,
        userGroup: user?.group.groupName,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
  }
}

export const emailServiceApi = new EmailServiceApi();
