import { createContext, useCallback, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { authApi } from "src/api/auth";
import { Issuer } from "src/utils/auth";
import axios from "axios";
import { CleaningServices } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { paths } from "src/paths";
import deployEnvironment from "src/deployEnvironment";

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

var ActionType;
(function (ActionType) {
  ActionType["INITIALIZE"] = "INITIALIZE";
  ActionType["SIGN_IN"] = "SIGN_IN";
  ActionType["SIGN_UP"] = "SIGN_UP";
  ActionType["SIGN_OUT"] = "SIGN_OUT";
  ActionType["AUTO_LOGIN"] = "AUTO_LOGIN";
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  SIGN_IN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  AUTO_LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  ssoSignIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  autoLogin: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const initialize = useCallback(async () => {
    try {
      const accessToken = window.sessionStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        const user = await authApi.me({ accessToken });

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [dispatch]);

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (email, password) => {
      const accessToken = await authApi.signIn({ email, password });

      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const ssoSignIn = useCallback(
    async (accessToken) => {
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_IN,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (email, name, password) => {
      const { accessToken } = await authApi.signUp({ email, name, password });
      const user = await authApi.me({ accessToken });

      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.SIGN_UP,
        payload: {
          user,
        },
      });
    },
    [dispatch]
  );

  const signOut = useCallback(async () => {
    sessionStorage.removeItem(STORAGE_KEY);
    dispatch({ type: ActionType.SIGN_OUT });
  }, [dispatch]);

  const autoLogin = useCallback(
    async (accessToken, link) => {
      if (!accessToken) navigate("/404", { replace: true });

      const user = await axios
        .get(`${API}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .catch((error) => {
          navigate("/401", { replace: true });
          throw new Error("This link has expired");
        });

      if (user?.error?.status === 404) {
        navigate("/401", { replace: true });
        throw new Error("This link has expired");
      }

      // Login user
      sessionStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: ActionType.AUTO_LOGIN,
        payload: {
          user,
        },
      });
      // await new Promise((resolve) => setTimeout(resolve, 1000));
    },
    [dispatch]
  );

  const sendPasswordRenewalEmail = useCallback(async (email) => {
    await axios.post(`${API}/api/generate-password-token`, {
      email,
    });
    navigate("/", { replace: true });
  });

  const changeForgotPassword = useCallback(async (password, token) => {
    await axios.post(`${API}/api/reset-password/${token}`, {
      password,
    });
    navigate("/", { replace: true });
  });

  const validateToken = useCallback(async (token) => {
    if (!token) navigate("/404", { replace: true });

    await axios.get(`${API}/api/validate-token/${token}`).catch((error) => {
      navigate("/401", { replace: true });
    });
  });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        ssoSignIn,
        signUp,
        signOut,
        autoLogin,
        sendPasswordRenewalEmail,
        changeForgotPassword,
        validateToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AuthConsumer = AuthContext.Consumer;
