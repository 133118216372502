import { createResourceId } from "src/utils/create-resource-id";
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from "src/utils/jwt";
import { wait } from "src/utils/wait";
import { users } from "./data";
import axios from "axios";
const deployEnvironment = require("../../deployEnvironment.js");

const API = deployEnvironment;
const STORAGE_KEY = "users";

// NOTE: We use sessionStorage since memory storage is lost after page reload.
//  This should be replaced with a server call that returns DB persisted data.

const getPersistedUsers = () => {
  try {
    const data = sessionStorage.getItem(STORAGE_KEY);

    if (!data) {
      return [];
    }

    return JSON.parse(data);
  } catch (err) {
    console.error(err);
    return [];
  }
};

const persistUser = (user) => {
  try {
    const users = getPersistedUsers();
    const data = JSON.stringify([...users, user]);
    sessionStorage.setItem(STORAGE_KEY, data);
  } catch (err) {
    console.error(err);
  }
};

class AuthApi {
  async signIn(request) {
    const { email, password } = request;

    const user = await axios
      .post(`${API}/api/auth/local`, {
        identifier: email,
        password: password,
      })
      .catch((error) => {
        throw new Error("Please check your email and password");
      });

    const { jwt: accessToken } = user.data;

    return accessToken;
  }

  async signUp(request) {
    const { email, name, password } = request;

    await wait(1000);

    return new Promise((resolve, reject) => {
      try {
        // Merge static users (data file) with persisted users (browser storage)
        const mergedUsers = [...users, ...getPersistedUsers()];

        // Check if a user already exists
        let user = mergedUsers.find((user) => user.email === email);

        if (user) {
          reject(new Error("User already exists"));
          return;
        }

        user = {
          id: createResourceId(),
          avatar: undefined,
          email,
          name,
          password,
          plan: "Standard",
        };

        persistUser(user);

        const accessToken = sign({ userId: user.id }, JWT_SECRET, {
          expiresIn: JWT_EXPIRES_IN,
        });

        resolve({ accessToken });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async me(request) {
    const { accessToken } = request;

    const userResponse = await axios.get(`${API}/api/users/me`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const user = userResponse.data;

    return new Promise((resolve, reject) => {
      try {
        // Decode access token
        // const decodedToken = decode(accessToken);

        // Merge static users (data file) with persisted users (browser storage)
        // const mergedUsers = [
        //   ...users,
        //   ...getPersistedUsers()
        // ];

        // Find the user
        // const { userId } = decodedToken;
        // const user = mergedUsers.find((user) => user.id === userId);

        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        resolve({
          id: user.id,
          email: user.email,
          name: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          confirmed: user.confirmed,
          group: user.group,
          permissions: user.permissions,
          role: user.role,
          contact: user.contact,
          hashedId: user.hashedId,
          companies: user.companies,
          brandContact: user.brand_contact,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
          provider: user.provider,
          blocked: user.blocked,
          brand: user.brand_contact?.brand,
        });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async checkUser(request) {
    const { email } = request;

    const userResponse = await axios.get(
      `${API}/api/users?filters[$and][0][email][$eq]=${email}`
    );
    const user = userResponse.data;
    return new Promise((resolve, reject) => {
      try {
        if (!user) {
          reject(new Error("Invalid authorization token"));
          return;
        }

        resolve({
          id: user.id,
          // avatar: user.avatar,
          email: user.email,
          name: user.username,
          firstName: user.firstName,
          lastName: user.lastName,
          // plan: user.plan
        });
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const authApi = new AuthApi();
