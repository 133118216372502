import { useEffect, useReducer, useCallback } from "react";
import { usePageView } from "src/hooks/use-page-view";
import { useParams } from "react-router-dom";
import { useAuth } from "src/hooks/use-auth";
import { useSearchParams } from "src/hooks/use-search-params";
import { paths } from "src/paths";
import { useMounted } from "src/hooks/use-mounted";

const AutoLoginPage = ({ link }) => {
  const isMounted = useMounted();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const { autoLogin } = useAuth();
  const params = useParams();
  const accessToken = params?.jwt;

  const login = async () => {
    try {
      await autoLogin(accessToken, link);

      if (isMounted()) {
        // returnTo could be an absolute path
        window.location.href =
          returnTo || paths.dashboard.linesheets.createLinesheet;
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    login();
  }, []);

  usePageView();

  return null;
};

export default AutoLoginPage;
