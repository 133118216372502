import { combineReducers } from "@reduxjs/toolkit";
import { reducer as calendarReducer } from "src/slices/calendar";
import { reducer as chatReducer } from "src/slices/chat";
import { reducer as kanbanReducer } from "src/slices/kanban";
import { reducer as mailReducer } from "src/slices/mail";
import { reducer as linesheetReducer } from "src/slices/linesheet";
import { reducer as priceManagementReducer } from "src/slices/price-management";
import { reducer as priceSetupReducer } from "src/slices/price-setup";
import { reducer as bookingProcessReducer } from "src/slices/booking-process";
import { reducer as productAttributesReducer } from "src/slices/product-attributes";
import { reducer as purchaseOrdersReducer } from "src/slices/purchase-orders";
import { reducer as eTafReducer } from "src/slices/e-taf";

export const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  linesheet: linesheetReducer,
  priceManagement: priceManagementReducer,
  priceSetup: priceSetupReducer,
  bookingProcess: bookingProcessReducer,
  productAttributes: productAttributesReducer,
  purchaseOrders: purchaseOrdersReducer,
  eTaf: eTafReducer,
});
