import axios from "axios";
const deployEnvironment = require("../../deployEnvironment.js");

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class LinesheetApi {
  // Brands
  async getBrands() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getBrandsResponse = await axios.get(`${API}/api/brands/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const brands = getBrandsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!brands) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(brands);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async getInvestedBrands() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getInvestedBrandsResponse = await axios.get(
      `${API}/api/invested-brands/list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const brands = getInvestedBrandsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!brands) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(brands);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Seasons
  async getSeasons() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getSeasonsResponse = await axios.get(`${API}/api/seasons/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const seasons = getSeasonsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!seasons) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(seasons);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Categories
  async getCategories() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getCategoriesResponse = await axios.get(
      `${API}/api/categories/list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const categories = getCategoriesResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!categories) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(categories);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Linesheets
  async getLinesheetList() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetsResponse = await axios.get(
      `${API}/api/linesheets/list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheets = getLinesheetsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheets) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheets);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async createLinesheet(data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const linesheetBody = data;

    try {
      const createLinesheetResponse = await axios.post(
        `${API}/api/linesheets/create`,
        linesheetBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return createLinesheetResponse.data;
    } catch (err) {
      throw err.response.data;
    }
  }

  async deleteLinesheet(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const deleteLinesheetResponse = await axios.delete(
      `${API}/api/linesheets/delete/${linesheetId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const linesheetDeleted = deleteLinesheetResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetDeleted) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetDeleted);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async getLinesheet(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetResponse = await axios.get(
      `${API}/api/linesheets/${linesheetId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheet = getLinesheetResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheet) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheet);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async getOPSSupportUser(brand) {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);

      const getBrandsResponse = await axios.get(`${API}/api/brands/list`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const foundBrands = getBrandsResponse.data;

      const opsSupportUserId = foundBrands.filter(
        (foundBrand) => foundBrand.name === brand
      )[0]?.ops_support;

      if (!opsSupportUserId) return null;

      const getHSUsersResponse = await axios.get(
        `${API}/api/hs-user/${opsSupportUserId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      const foundHSUserData = getHSUsersResponse.data;

      return foundHSUserData;
    } catch (err) {
      throw err.response.data;
    }
  }

  async updateLinesheet(linesheetId, data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const linesheetBody = data;

    try {
      const updateLinesheetResponse = await axios.put(
        `${API}/api/linesheets/${linesheetId}`,
        linesheetBody,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return updateLinesheetResponse.data;
    } catch (err) {
      throw err.response.data;
    }
  }

  // Linesheet Items References
  async getLinesheetItemsReferences() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetItemsReferencesResponse = await axios.get(
      `${API}/api/linesheet-items/reference-data`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetItemsReferences = getLinesheetItemsReferencesResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetItemsReferences) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetItemsReferences);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Linesheet Items
  async getLinesheetItemsList(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetItemsResponse = await axios.get(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/list`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetItems = getLinesheetItemsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetItems) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetItems);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Create/Update Linesheet Items (Bulk)
  async createUpdateLinesheetItems(linesheetId, data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const linesheetItemsBody = data;

    const createUpdateLinesheetItemsResponse = await axios.post(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/manage`,
      linesheetItemsBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetItems = createUpdateLinesheetItemsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetItems) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetItems);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Delete Linesheet Item
  async deleteLinesheetItem(linesheetId, linesheetItemId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const createUpdateLinesheetItemsResponse = await axios.delete(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/${linesheetItemId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetDeleted = createUpdateLinesheetItemsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetDeleted) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetDeleted);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Brand Request Delete Linesheet Item
  async brandRequestDeleteLinesheetItem(linesheetId, linesheetItemId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const brandRequestDeleteLinesheetItemResponse = await axios.delete(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/${linesheetItemId}/request-delete`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const brandRequestDeleteLinesheetItem =
      brandRequestDeleteLinesheetItemResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!brandRequestDeleteLinesheetItem) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(brandRequestDeleteLinesheetItem);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Change Linesheet Status
  async changeLinesheetStatus(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const changeLinesheetStatusResponse = await axios.put(
      `${API}/api/linesheets/${linesheetId}/status`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const updatedLinesheetStatus = changeLinesheetStatusResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!updatedLinesheetStatus) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(updatedLinesheetStatus);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Change Linesheet Price Status
  async changeLinesheetPriceStatus(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const changeLinesheetPriceStatusResponse = await axios.put(
      `${API}/api/linesheets/${linesheetId}/price-status`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const updatedLinesheetPriceStatus = changeLinesheetPriceStatusResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!updatedLinesheetPriceStatus) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(updatedLinesheetPriceStatus);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Revert Linesheet Price Status Approved - Pending
  async revertLinesheetPriceStatus(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const revertLinesheetPriceStatusResponse = await axios.put(
      `${API}/api/linesheets/${linesheetId}/revert-price-status`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const updatedLinesheetPriceStatus = revertLinesheetPriceStatusResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!updatedLinesheetPriceStatus) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(updatedLinesheetPriceStatus);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Change Linesheet Product Attributes Status
  async changeLinesheetProductAttributesStatus(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const changeLinesheetProductAttributesStatusResponse = await axios.put(
      `${API}/api/linesheets/${linesheetId}/product-attributes-status`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const updatedLinesheetProductAttributesStatus =
      changeLinesheetProductAttributesStatusResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!updatedLinesheetProductAttributesStatus) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(updatedLinesheetProductAttributesStatus);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Revert Linesheet Product Attributes Status
  async revertLinesheetProductAttributesStatus(linesheetId, data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const revertLinesheetProductAttrivbutesStatusResponse = await axios.put(
      `${API}/api/linesheets/${linesheetId}/revert-product-attributes-status`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const updatedLinesheetProductAttributesStatus =
      revertLinesheetProductAttrivbutesStatusResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!updatedLinesheetProductAttributesStatus) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(updatedLinesheetProductAttributesStatus);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Get Linesheet Item
  async getLinesheetItem(linesheetId, linesheetItemId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetItemResponse = await axios.get(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/${linesheetItemId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetItem = getLinesheetItemResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetItem) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetItem);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Create Linesheet Item
  async createLinesheetItem(linesheetId, linesheetItem) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const linesheetItemBody = linesheetItem;

    const createLinesheetItemResponse = await axios.post(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/create`,
      linesheetItemBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetItemResponse = createLinesheetItemResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetItemResponse) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetItemResponse);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Update Linesheet Item
  async updateLinesheetItem(linesheetId, linesheetItemId, linesheetItem) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const linesheetItemBody = linesheetItem;

    const updateLinesheetItemResponse = await axios.put(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/${linesheetItemId}`,
      linesheetItemBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const updatedLinesheetItemResponse = updateLinesheetItemResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!updatedLinesheetItemResponse) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(updatedLinesheetItemResponse);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Get Linesheet Items Autocomplete Data
  async getLinesheetItemsAutocompleteData(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const autocompleteDataResponse = await axios.get(
      `${API}/api/linesheet-items/autocomplete-data/${linesheetId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const autocompleteData = autocompleteDataResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!autocompleteData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(autocompleteData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Get Linesheet Items Carryover Data
  async getLinesheetItemsCarryoverData(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const carryoverDataResponse = await axios.get(
      `${API}/api/linesheet-items/carryover-data/${linesheetId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const carryoverData = carryoverDataResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!carryoverData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(carryoverData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async uploadLinesheetItemsFromExcel(type, linesheetId, formData) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    try {
      let endpoint;

      switch (type) {
        case "excel":
          endpoint = `${API}/api/linesheet/${linesheetId}/linesheet-items/import/excel`;
          break;
        case "zedonkExcel":
          endpoint = `${API}/api/linesheet/${linesheetId}/linesheet-items/import/zedonk-excel`;
          break;
        default:
          endpoint = null;
          break;
      }

      const excelImportDataResponse = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return excelImportDataResponse.data;
    } catch (err) {
      throw err.response.data;
    }
  }

  // Import Linesheet Items (Single)
  async importLinesheetItem(linesheetId, data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const linesheetItemsBody = data;

    const importLinesheetItemResponse = await axios.post(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/import/linesheet-item`,
      linesheetItemsBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetItem = importLinesheetItemResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetItem) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetItem);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async exportLinesheetItems(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const exportLinesheetItemsResponse = await axios.get(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/export/csv-or-excel`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const exportLinesheetItemsData = exportLinesheetItemsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!exportLinesheetItemsData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(exportLinesheetItemsData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async exportLinesheetItemsPriceManagement(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const exportLinesheetItemsPriceManagementResponse = await axios.get(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/export/price-management-csv-or-excel`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const exportLinesheetItemsData =
      exportLinesheetItemsPriceManagementResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!exportLinesheetItemsData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(exportLinesheetItemsData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async exportLinesheetItemsExcelPriceManagement(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const exportLinesheetItemsPriceManagementResponse = await axios.get(
      `${API}/api/linesheet/${linesheetId}/linesheet-items/export/price-management-excel`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const exportLinesheetItemsData =
      exportLinesheetItemsPriceManagementResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!exportLinesheetItemsData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(exportLinesheetItemsData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async downloadLinesheetTemplate() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const linesheetId = await this.getLinesheetTemplate();

    const downloadLinesheetTemplateResponse = await axios.get(
      `${API}/api/upload/files/${linesheetId.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const downloadLinesheetTemplateData =
      downloadLinesheetTemplateResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!downloadLinesheetTemplateData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(downloadLinesheetTemplateData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async getLinesheetPriceLists(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const priceListsLinesheetResponse = await axios.get(
      `${API}/api/linesheets/${linesheetId}/price-lists`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const priceListsLinesheetData = priceListsLinesheetResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!priceListsLinesheetData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(priceListsLinesheetData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async getLinesheetPriceSetup(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const priceSetupLinesheetResponse = await axios.get(
      `${API}/api/linesheets/${linesheetId}/price-setup`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const priceSetupLinesheetData = priceSetupLinesheetResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!priceSetupLinesheetData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(priceSetupLinesheetData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Linesheet History
  async getLinesheetHistory(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetHistoryResponse = await axios.get(
      `${API}/api/linesheets/${linesheetId}/history`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetHistory = getLinesheetHistoryResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetHistory) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetHistory);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Linesheet Email History
  async getLinesheetEmailHistory(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetEmailHistoryResponse = await axios.get(
      `${API}/api/linesheets/${linesheetId}/email-history`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetEmailHistory = getLinesheetEmailHistoryResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetEmailHistory) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetEmailHistory);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Sync Linesheet To Netsuite
  async syncLinesheetToNetsuite(linesheetId, data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const syncLinesheetToNetsuiteSetupBody = data;

    const syncLinesheetToNetsuiteResponse = await axios.post(
      `${API}/api/linesheets/${linesheetId}/sync-to-netsuite`,
      syncLinesheetToNetsuiteSetupBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const syncLinesheetToNetsuite = syncLinesheetToNetsuiteResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!syncLinesheetToNetsuite) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(syncLinesheetToNetsuite);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Get Linesheet Netsuite Items
  async getLinesheetNetsuiteItems(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetNetsuiteItemsResponse = await axios.get(
      `${API}/api/linesheets/${linesheetId}/netsuite-items`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetNetsuiteItems = getLinesheetNetsuiteItemsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetNetsuiteItems) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetNetsuiteItems);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Upload Price Management Prices From Excel
  async uploadPriceManagementItemsPricesFromExcel(linesheetId, formData) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    try {
      const endpoint = `${API}/api/linesheet/${linesheetId}/price-management/import/excel`;

      const excelImportDataResponse = await axios.post(endpoint, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      return excelImportDataResponse.data;
    } catch (err) {
      throw err.response.data;
    }
  }

  // Blank Linesheet Template
  async getLinesheetTemplate() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetTemplateResponse = await axios.get(
      `${API}/api/linesheets/getLinesheetTemplate`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetTemplate = getLinesheetTemplateResponse.data;

    return linesheetTemplate;
  }
  async uploadLinesheetTemplate(linesheetTemplateFile, oldFileName) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const result = await axios.get(`${API}/api/upload/files`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const foundFile = result.data.find((file) => file.name === oldFileName);

    if (foundFile) {
      const oldFileId = foundFile.id;

      await axios.delete(`${API}/api/upload/files/${oldFileId}`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
    }

    await axios.post(`${API}/api/upload`, linesheetTemplateFile, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  // Zedonk Mappings
  async getZedonkMappings(brandName) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getZedonkMappingsResponse = await axios.post(
      `${API}/api/linesheets/lir-zedonk-mappings`,
      { brandName },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const zedonkMappings = getZedonkMappingsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!zedonkMappings) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(zedonkMappings);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async updateZedonkMappings(brandName, values) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const updateZedonkMappingsResponse = await axios.post(
      `${API}/api/linesheets/update-zedonk-mappings`,
      { brandName, values },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const zedonkMappings = updateZedonkMappingsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!zedonkMappings) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(zedonkMappings);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
  async getZedonkTemplates() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const zedonkTemplatesResponse = await axios.get(
      `${API}/api/linesheets/get-zedonk-templates`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const zedonkTemplates = zedonkTemplatesResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!zedonkTemplates) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(zedonkTemplates);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const linesheetApi = new LinesheetApi();
