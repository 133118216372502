import { tokens } from "../tokens";

export const de = {
  [tokens.common.languageChanged]: "Language changed",
  [tokens.nav.academy]: "Academy",
  [tokens.nav.account]: "Account",
  [tokens.nav.auth]: "Auth",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.browse]: "Browse",
  [tokens.nav.calendar]: "Calendar",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Checkout",
  [tokens.nav.concepts]: "Concepts",
  [tokens.nav.contact]: "Contact",
  [tokens.nav.course]: "Course",
  [tokens.nav.create]: "Create",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customers]: "Customers",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.details]: "Details",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Edit",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Feed",
  [tokens.nav.fileManager]: "File Manager",
  [tokens.nav.finance]: "Finance",
  [tokens.nav.fleet]: "Fleet",
  [tokens.nav.forgotPassword]: "Forgot Password",
  [tokens.nav.invoiceList]: "Invoices",
  [tokens.nav.jobList]: "Job Listings",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.list]: "List",
  [tokens.nav.login]: "Login",
  [tokens.nav.logistics]: "Logistics",
  [tokens.nav.mail]: "Mail",
  [tokens.nav.management]: "Management",
  [tokens.nav.orderList]: "Orders",
  [tokens.nav.overview]: "Overview",
  [tokens.nav.pages]: "Pages",
  [tokens.nav.postCreate]: "Post Create",
  [tokens.nav.postDetails]: "Post Details",
  [tokens.nav.postList]: "Post List",
  [tokens.nav.pricing]: "Pricing",
  [tokens.nav.productList]: "Products",
  [tokens.nav.profile]: "Profile",
  [tokens.nav.register]: "Register",
  [tokens.nav.resetPassword]: "Reset Password",
  [tokens.nav.socialMedia]: "Social Media",
  [tokens.nav.verifyCode]: "Verify Code",
  [tokens.nav.settings]: "Settings",
  [tokens.nav.linesheets]: "Linesheets",

  //
  // Top Nav
  //

  // Logout
  [tokens.topNav.logout.title]:
    "Are you sure you want to logout from your current session?",
  [tokens.topNav.logout.logoutButton]: "Log Out",
  [tokens.topNav.logout.cancelButton]: "Cancel",

  //
  // Linesheets
  //

  // Linesheets Table
  [tokens.linesheets.newLinesheetButton]: "New Linesheet",
  [tokens.linesheets.editLinesheetActionButton]: "Edit Linesheet",
  [tokens.linesheets.manageProductsActionButton]: "Manage Products",
  [tokens.linesheets.table.name]: "Linesheet Name",
  [tokens.linesheets.table.status]: "Status",
  [tokens.linesheets.table.brand]: "Brand",
  [tokens.linesheets.table.season]: "Season",
  [tokens.linesheets.table.category]: "Category",
  [tokens.linesheets.table.noOfStyles]: "no. of Styles",
  [tokens.linesheets.table.inboundDelivery]: "Inbound Delivery",
  [tokens.linesheets.table.outboundDelivery]: "Outbound Delivery",
  [tokens.linesheets.table.windowStart]: "Start",
  [tokens.linesheets.table.windowEnd]: "End",
  [tokens.linesheets.table.syncToNetSuite]: "Sync to NetSuite",
  [tokens.linesheets.table.syncToJoor]: "Sync to Joor",
  [tokens.linesheets.table.inactive]: "Inactive",
  // Create Linesheet
  [tokens.linesheets.createLinesheet.linesheets]: "Linesheets",
  [tokens.linesheets.createLinesheet.title]: "Create Linesheet",
  [tokens.linesheets.createLinesheet.submitButton]: "Submit",
  [tokens.linesheets.createLinesheet.cancelButton]: "Cancel",
  // Edit Linesheet
  [tokens.linesheets.editLinesheet.linesheets]: "Linesheets",
  [tokens.linesheets.editLinesheet.title]: "Edit Linesheet",
  [tokens.linesheets.editLinesheet.submitButton]: "Submit",
  [tokens.linesheets.editLinesheet.cancelButton]: "Cancel",
  // Manage Linesheet Item Products
  [tokens.linesheets.newLinesheetItemButton]: "New Row",
  [tokens.linesheets.saveChangesLinesheetItemButton]: "Save Changes",
  [tokens.linesheets.exportCSVButton]: "Export CSV for NetSuite",
  [tokens.linesheets.exportExcelButton]: "Export Excel",
  [tokens.linesheets.importFileButton]: "Import Excel",
  [tokens.linesheets.importZedonkFileButton]: "Import Zedonk",
  // Delete Linesheet
  [tokens.linesheets.deleteLinesheetActionButton]: "Delete Linesheet",
  // Linesheet Products Table Actions
  [tokens.linesheets.deleteProductActionButton]: "Delete Product",
  [tokens.linesheets.deleteColourVariantActionButton]: "Delete Colour Variant",
  [tokens.linesheets.duplicateProductActionButton]: "Duplicate Product",
  [tokens.linesheets.editProductActionButton]: "Edit Product",
  [tokens.linesheets.manageColourVariantActionButton]: "Manage Colour Variants",
  // Manage Linesheet Products Table
  [tokens.linesheets.manageProductsTable.season]: "Season",
  [tokens.linesheets.manageProductsTable.brand]: "Brand",
  [tokens.linesheets.manageProductsTable.netsuiteStyleCode]:
    "Netsuite Style Code",
  [tokens.linesheets.manageProductsTable.styleCode]: "Style Code",
  [tokens.linesheets.manageProductsTable.styleName]: "Style Name",
  [tokens.linesheets.manageProductsTable.styleDescription]: "Style Description",
  [tokens.linesheets.manageProductsTable.gender]: "Gender",
  [tokens.linesheets.manageProductsTable.productCategory]: "Product Category",
  [tokens.linesheets.manageProductsTable.productSubCategory]:
    "Product Sub-Category",
  [tokens.linesheets.manageProductsTable.colourDescription]:
    "Colour Description",
  [tokens.linesheets.manageProductsTable.primaryColour]: "Primary Colour Name",
  [tokens.linesheets.manageProductsTable.secondaryColour]:
    "Secondary Colour Name",
  [tokens.linesheets.manageProductsTable.netsuiteColourCode]:
    "Netsuite Colour Code",
  [tokens.linesheets.manageProductsTable.materialComposition]:
    "Material Composition",
  [tokens.linesheets.manageProductsTable.customsClassification]:
    "Customs Classification",
  [tokens.linesheets.manageProductsTable.commodityCode]: "Commodity Code",
  [tokens.linesheets.manageProductsTable.countryOfOrigin]: "Country Of Origin",
  [tokens.linesheets.manageProductsTable.sizeRunCategory]: "Size Run Category",
  [tokens.linesheets.manageProductsTable.fullSizeRun]: "Full Size Run",
  [tokens.linesheets.manageProductsTable.sizesAvailableWithinFullSizeRun]:
    "Sizes Available Within Full Size Run",
  [tokens.linesheets.manageProductsTable.manufacturer]: "Manufacturer",
  [tokens.linesheets.manageProductsTable.linesheetName]: "Linesheet Name",
  [tokens.linesheets.manageProductsTable.inboundDelivery]: "Inbound Delivery",
  [tokens.linesheets.manageProductsTable.inboundDeliveryOpen]:
    "Inbound Delivery Open",
  [tokens.linesheets.manageProductsTable.inboundDeliveryClose]:
    "Inbound Delivery Close",
  [tokens.linesheets.manageProductsTable.outboundDelivery]: "Outbound Delivery",
  [tokens.linesheets.manageProductsTable.outboundDeliveryOpen]:
    "Outbound Delivery Open",
  [tokens.linesheets.manageProductsTable.outboundDeliveryClose]:
    "Outbound Delivery Close",
  [tokens.linesheets.manageProductsTable.priceList1]: "Price List 1",
  [tokens.linesheets.manageProductsTable.currency1]: "Currency 1",
  [tokens.linesheets.manageProductsTable.purchasePrice1]: "Purchase Price 1",
  [tokens.linesheets.manageProductsTable.wholesalePrice1]: "Wholesale Price 1",
  [tokens.linesheets.manageProductsTable.retailPrice1]: "Retail Price 1",

  // Manage Linesheet Delete Modal
  [tokens.linesheets.deleteLinesheetModal.title]: "Delete Linesheet",
  [tokens.linesheets.deleteLinesheetModal.message]:
    "Are you sure you want to delete this linesheet? This action cannot be undone.",
  [tokens.linesheets.deleteLinesheetModal.submitButtonLabel]: "Delete",
  [tokens.linesheets.deleteLinesheetModal.cancelButtonLabel]: "Cancel",

  // Manage Linesheet Products Table Delete Modal
  [tokens.linesheets.deleteProductModal.title]: "Delete Product",
  [tokens.linesheets.deleteProductModal.message]:
    "Are you sure you want to delete this item? This action cannot be undone.",
  [tokens.linesheets.deleteProductModal.submitButtonLabel]: "Delete",
  [tokens.linesheets.deleteProductModal.cancelButtonLabel]: "Cancel",

  // Confirm Linesheet Table Modal
  [tokens.linesheets.confirmModal.title]: "Confirm Linesheet",
  [tokens.linesheets.confirmModal.message]:
    'Are you sure you want to Confirm this Linesheet? You will not be able to edit it anymore. After you press on "Confirm" the Linesheet status will be changed to "Pending" until a Tomorrow administrator will review it.',
  [tokens.linesheets.confirmModal.submitButtonLabel]: "Confirm",
  [tokens.linesheets.confirmModal.cancelButtonLabel]: "Cancel",
  [tokens.linesheets.confirmModal.okButtonLabel]: "Ok",
  [tokens.linesheets.confirmModal.errors]:
    'Please fix the highlighted Product(s), then click on Save Changes and in the end click again on "Confirm". You can see the errors if you hover on the values from the highlighted cells.',

  // Approve Linesheet Table Modal
  [tokens.linesheets.approveModal.title]: "Approve Linesheet",
  [tokens.linesheets.approveModal.message]:
    "Are you sure you want to Approve this Linesheet?",
  [tokens.linesheets.approveModal.submitButtonLabel]: "Approve",
  [tokens.linesheets.approveModal.cancelButtonLabel]: "Cancel",
  [tokens.linesheets.approveModal.okButtonLabel]: "Ok",
  [tokens.linesheets.approveModal.errors]:
    'Please fix the highlighted Product(s), then click on Save Changes and in the end click again on "Approve". You can see the errors if you hover on the values from the highlighted cells.',

  // Confirm/Approve Linesheet Buttons
  [tokens.linesheets.confirmLinesheetButton]: "Confirm",
  [tokens.linesheets.approveLinesheetButton]: "Approve",

  // Submit Imported Linesheet Items File (Excel)
  [tokens.linesheets.importModal.title]: "Import Data from Excel",
  [tokens.linesheets.importModal.message]:
    "You are about to import the linesheet items from the selected file into this linesheet.",
  [tokens.linesheets.importModal.importantMessage]:
    "Warning: This process will delete all the existing items from the current linesheet! Are you sure?",
  [tokens.linesheets.importModal.submitButtonLabel]: "Upload",

  // Submit Imported Linesheet Items File (Zedonk)
  [tokens.linesheets.importZedonkModal.title]: "Import Data from",
  [tokens.linesheets.importZedonkModal.message]:
    "You are about to import the linesheet items from the selected file into this linesheet.",
  [tokens.linesheets.importZedonkModal.importantMessage]:
    "Warning: This process will delete all the existing items from the current linesheet! Are you sure?",
  [tokens.linesheets.importZedonkModal.submitButtonLabel]: "Upload",

  // Submit Imported Linesheet Items File Error
  [tokens.linesheets.importErrorModal.title]: "Import error",
  [tokens.linesheets.importErrorModal.submitButtonLabel]: "Ok",

  // Create/Edit (form) Linesheet Item Colour Variant Delete Modal
  [tokens.linesheets.deleteColourVariantModal.title]: "Delete Colour Variant",
  [tokens.linesheets.deleteColourVariantModal.message]:
    "Are you sure you want to delete this colour variant? This action cannot be undone.",
  [tokens.linesheets.deleteColourVariantModal.submitButtonLabel]: "Delete",
  [tokens.linesheets.deleteColourVariantModal.cancelButtonLabel]: "Cancel",

  // New Linesheet Item Colour Variant Modal (form)
  [tokens.linesheets.newColourVariantModal.title]: "New Colour Variant",
  [tokens.linesheets.newColourVariantModal.message]:
    "Are you sure you want to create this colour variant?",
  [tokens.linesheets.newColourVariantModal.submitButtonLabel]: "Submit",
  [tokens.linesheets.newColourVariantModal.cancelButtonLabel]: "Cancel",

  // Redirect To Colour Variants Page Modal
  [tokens.linesheets.redirectColourVariantsModal.title]:
    "Create Colour Variants",
  [tokens.linesheets.redirectColourVariantsModal.message]:
    "Do you want to also add colour variants to the new style that you created?",
  [tokens.linesheets.redirectColourVariantsModal.submitButtonLabel]: "Yes",
  [tokens.linesheets.redirectColourVariantsModal.cancelButtonLabel]: "No",

  //
  // Price Management
  //

  // Price Management Table (Linesheets)
  [tokens.priceManagement.lineModificationActionButton]: "Line Modification",
  [tokens.priceManagement.changeStatusActionButton]: "Change Status",

  [tokens.priceManagement.table.name]: "Name",
  [tokens.priceManagement.table.priceStatus]: "Price Status",
  [tokens.priceManagement.table.brand]: "Brand",
  [tokens.priceManagement.table.season]: "Season",
  [tokens.priceManagement.table.category]: "Category",
  [tokens.priceManagement.table.inboundDelivery]: "Inbound Delivery",
  [tokens.priceManagement.table.outboundDelivery]: "Outbound Delivery",
  [tokens.priceManagement.table.windowStart]: "Start",
  [tokens.priceManagement.table.windowEnd]: "End",
  [tokens.priceManagement.table.syncToNetSuite]: "Sync to NetSuite",
  [tokens.priceManagement.table.syncToJoor]: "Sync to Joor",
  [tokens.priceManagement.table.inactive]: "Inactive",

  // Price Management (Linesheet Items Page)
  [tokens.priceManagement.calculatePricesButton]: "Calculate Prices",

  // Price Management Calculate Prices Modal
  [tokens.priceManagement.calculatePricesModal.title]: "Calculate Prices",
  [tokens.priceManagement.calculatePricesModal.message]:
    "Are you sure you want to calculate all the price lists?",
  [tokens.priceManagement.calculatePricesModal.importantMessage]:
    "All existing prices from the price lists of the current linesheet will be overwritten",
  [tokens.priceManagement.calculatePricesModal.submitButtonLabel]: "Submit",
  [tokens.priceManagement.calculatePricesModal.cancelButtonLabel]: "Cancel",
  [tokens.priceManagement.calculatePricesModal.okButtonLabel]: "Ok",

  // Price Management Approve Modal
  [tokens.priceManagement.approveModal.title]: "Approve Linesheet Price Lists",
  [tokens.priceManagement.approveModal.message]:
    "Are you sure you want to Approve those price lists?",
  [tokens.priceManagement.approveModal.submitButtonLabel]: "Approve",
  [tokens.priceManagement.approveModal.cancelButtonLabel]: "Cancel",

  //
  // Product Attributes
  //
  [tokens.productAttributes.manageProductAttributesActionButton]:
    "Manage Product Attributes",
  [tokens.productAttributes.changeStatusActionButton]: "Change Status",

  // Product Attributes Table (Linesheets)
  [tokens.productAttributes.table.name]: "Name",
  [tokens.productAttributes.table.productAttributesStatus]:
    "Product Attributes Status",
  [tokens.productAttributes.table.brand]: "Brand",
  [tokens.productAttributes.table.season]: "Season",
  [tokens.productAttributes.table.category]: "Category",
  [tokens.productAttributes.table.inboundDelivery]: "Inbound Delivery",
  [tokens.productAttributes.table.outboundDelivery]: "Outbound Delivery",
  [tokens.productAttributes.table.windowStart]: "Start",
  [tokens.productAttributes.table.windowEnd]: "End",
  [tokens.productAttributes.table.syncToNetSuite]: "Sync to NetSuite",
  [tokens.productAttributes.table.syncToJoor]: "Sync to Joor",
  [tokens.productAttributes.table.inactive]: "Inactive",

  // Product Attributes Table (Linesheet Products)
  [tokens.productAttributes.productsTable.internalId]: "Internal ID",
  [tokens.productAttributes.productsTable.name]: "Name",
  [tokens.productAttributes.productsTable.supplierStyleCode]:
    "Supplier Style Code",
  [tokens.productAttributes.productsTable.styleDescription]:
    "Style Description",
  [tokens.productAttributes.productsTable.purchaseDescription]:
    "Purchase Description",
  [tokens.productAttributes.productsTable.tomorrowColourCode]:
    "Tomorrow Colour Code",
  [tokens.productAttributes.productsTable.tomorrowColourDescription]:
    "TMW Colour Description",
  [tokens.productAttributes.productsTable.matrixTomorrowColourDescription]:
    "Matrix TMW Colour Description",
  [tokens.productAttributes.productsTable.size]: "Size",
  [tokens.productAttributes.productsTable.customsClassification]:
    "Customs Woven/Knitted/Leather",
  [tokens.productAttributes.productsTable.commodityCode]: "Commodity Code",
  [tokens.productAttributes.productsTable.eanCode]: "EAN Code",
  [tokens.productAttributes.productsTable.manufacturerCode]:
    "Manufacturer Code",
  [tokens.productAttributes.productsTable.manufacturerName]:
    "Manufacturer Name",
  [tokens.productAttributes.productsTable.addressCity]: "AddressCity",
  [tokens.productAttributes.productsTable.stateCounty]: "State/County",
  [tokens.productAttributes.productsTable.zipPostCode]: "Zip/Post Code",
  [tokens.productAttributes.productsTable.country]: "Country",
  [tokens.productAttributes.productsTable.midCode]: "MID Code",
  [tokens.productAttributes.productsTable.composition]: "Composition",
  [tokens.productAttributes.productsTable.compositionExtras]:
    "Composition Extras (Lining, Hood etc.)",
  [tokens.productAttributes.productsTable.washingCareInstruction]:
    "Washing / Care Instruction",
  [tokens.productAttributes.productsTable.animalsScientificName]:
    "Animals Scientific Name",
  [tokens.productAttributes.productsTable.farmRaised]: "Farm Raised?",
  [tokens.productAttributes.productsTable.animalMaterial]:
    "Animal Material (%)",
  [tokens.productAttributes.productsTable.animalCountryOfOrigin]:
    "Animal Country of Origin",
  [tokens.productAttributes.productsTable.dyed]: "Dyed? (Leather/Fur)",
  [tokens.productAttributes.productsTable.itemWeightGross]:
    "Item Weight (Gross)",
  [tokens.productAttributes.productsTable.itemWeightNet]: "Item Weight (Net)",
  [tokens.productAttributes.productsTable.weightUnits]: "Weight Units",
  [tokens.productAttributes.productsTable.countryWhenceConsigned]:
    "Country Whence Consigned",

  // Submit Imported Linesheet Products File (Excel)
  [tokens.productAttributes.importModal.title]: "Import Data from Excel",
  [tokens.productAttributes.importModal.message]:
    "You are about to import the linesheet products from the selected file into this linesheet products attributes.",
  [tokens.productAttributes.importModal.importantMessage]:
    "Warning: This process will delete all the existing products from the current linesheet products attributes! Are you sure?",
  [tokens.productAttributes.importModal.submitButtonLabel]: "Upload",

  // Confirm Linesheet Product Attributes Table Modal
  [tokens.productAttributes.confirmModal.title]:
    "Confirm Linesheet - Product Attributes",
  [tokens.productAttributes.confirmModal.message]:
    'Are you sure you want to Confirm this Linesheet Product Attributes? You will not be able to edit it anymore. After you press on "Confirm" the Linesheet Product Attributes status will be changed to "Pending" until a Tomorrow administrator will review it.',
  [tokens.productAttributes.confirmModal.submitButtonLabel]: "Confirm",
  [tokens.productAttributes.confirmModal.cancelButtonLabel]: "Cancel",
  [tokens.productAttributes.confirmModal.okButtonLabel]: "Ok",

  // Approve Linesheet Product Attributes Table Modal
  [tokens.productAttributes.approveModal.title]:
    "Approve Linesheet - Product Attributes",
  [tokens.productAttributes.approveModal.message]:
    "Are you sure you want to Approve this Linesheet Product Attributes?",
  [tokens.productAttributes.approveModal.submitButtonLabel]: "Approve",
  [tokens.productAttributes.approveModal.cancelButtonLabel]: "Cancel",
  [tokens.productAttributes.approveModal.okButtonLabel]: "Ok",

  //
  // Purchase Orders
  //

  // Confirm Purchase Order Table Modal
  [tokens.purchaseOrders.confirmModal.title]: "Confirm Purchase Order",
  [tokens.purchaseOrders.confirmModal.message]:
    'ARE YOU SURE YOU WANT TO CONFIRM THIS PURCHASE ORDER? YOU WILL NOT BE ABLE TO EDIT IT ANYMORE. AFTER YOU PRESS ON "CONFIRM" TOMORROW ADMINISTRATORS WILL BE NOTIFIED TO CHECK AND APPROVE THE ORDER.',
  [tokens.purchaseOrders.confirmModal.submitButtonLabel]: "Confirm",
  [tokens.purchaseOrders.confirmModal.cancelButtonLabel]: "Cancel",

  // Approve Purchase Order Table Modal
  [tokens.purchaseOrders.approveModal.title]: "Approve Purchase Order",
  [tokens.purchaseOrders.approveModal
    .message]: `ARE YOU SURE YOU WANT TO APPROVE THIS PURCHASE ORDER? AFTER YOU PRESS ON "CONFIRM" NETSUITE SYNCHRONIZATION WILL BEGIN.`,
  [tokens.purchaseOrders.approveModal.submitButtonLabel]: "Confirm",
  [tokens.purchaseOrders.approveModal.cancelButtonLabel]: "Cancel",
};
