import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CustomButton } from "src/components/inputs";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { paths } from "src/paths";

const Page = () => {
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  usePageView();

  return (
    <>
      <Seo title="Error: Not Found" />
      <Box
        component="main"
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          py: "80px",
        }}
      >
        <Container maxWidth="lg">
          <Typography
            align="center"
            style={{
              fontFamily: "tmwCustomFont",
              fontSize: !mdUp ? 55 : 45,
              color: "black",
              letterSpacing: 3,
              userSelect: "none",
              textTransform: "uppercase",
            }}
          >
            Error 404
          </Typography>
          <Typography
            align="center"
            style={{
              fontFamily: "tmwCustomFont",
              fontSize: !mdUp ? 15 : 12,
              color: "black",
              letterSpacing: 3,
              userSelect: "none",
              textTransform: "uppercase",
            }}
          >
            This page cannot be found
          </Typography>
          <Typography
            align="center"
            style={{
              marginTop: "50px",
              fontFamily: "tmwCustomFont",
              fontSize: !mdUp ? 11 : 8,
              color: "black",
              letterSpacing: 3,
              userSelect: "none",
              textTransform: "uppercase",
            }}
          >
            The page doesn&apos;t exist or an error has occured,
            <br />
            Please check the address and try again.
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 6,
            }}
          >
            <CustomButton
              label="Back to Home"
              component={RouterLink}
              href={paths.index}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Page;
