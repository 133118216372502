import { useEffect, useContext, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { paths } from "src/paths";
import { useMounted } from "src/hooks/use-mounted";
import { useAuth } from "src/hooks/use-auth";
import { useParams } from "react-router-dom";
import { AlertContext } from "../../../components/alert/AlertContext";
import { CustomButton, CustomTypography } from "src/components/inputs";

const initialValues = {
  password: "",
  passwordConfirm: "",
};

const validationSchema = Yup.object({
  password: Yup.string()
    .min(7, "Must be at least 7 characters")
    .max(255)
    .required("Required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

const Page = () => {
  const [viewPage, setViewPage] = useState(false);
  const isMounted = useMounted();
  const { changeForgotPassword, validateToken } = useAuth();
  const { showAlert } = useContext(AlertContext);
  const params = useParams();
  const token = params?.token;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  // Function to send the token to the backend API
  const verifyTokenValidity = async () => {
    const token = params?.token;
    try {
      await validateToken(token);
      setViewPage(true);
    } catch (err) {
      console.error(err);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        if (isMounted()) {
          await changeForgotPassword(values.password, token);

          showAlert("Password changed successfully!", "success");
        }
      } catch (err) {
        console.error(err);

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    verifyTokenValidity();
  }, []);

  return (
    viewPage && (
      <>
        <Seo title="Change Password" />
        <div>
          <Card
            elevation={16}
            style={{
              borderRadius: 0,
              padding: "15px 10px 0 10px",
              width: mdUp ? "" : "500px",
            }}
          >
            <CardContent>
              <Box sx={{ mb: 4 }}>
                <Link
                  color="text.primary"
                  component={RouterLink}
                  href={paths.auth.jwt.login}
                  sx={{
                    alignItems: "center",
                    display: "inline-flex",
                    marginBottom: "25px",
                  }}
                  underline="hover"
                >
                  <SvgIcon sx={{ mr: 1 }}>
                    <ArrowLeftIcon />
                  </SvgIcon>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontSize: 13,
                      color: "black",
                      letterSpacing: 3,
                      fontWeight: 600,
                      userSelect: "none",
                      textTransform: "uppercase",
                      fontFamily: "",
                      marginLeft: "10px",
                    }}
                  >
                    Login
                  </Typography>
                </Link>
              </Box>
              <CustomTypography message="Change Password" title />
              <form noValidate onSubmit={formik.handleSubmit}>
                <Stack spacing={3} pt={6}>
                  <Stack>
                    <span
                      style={{
                        fontFamily: "tmwCustomFont",
                        fontSize: 13,
                        color: "black",
                        letterSpacing: 3,
                        fontWeight: 500,
                        userSelect: "none",
                        textTransform: "uppercase",
                      }}
                    >
                      Password
                    </span>
                    <TextField
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottom: "1px solid #E6E6E6",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "2px solid #333",
                        },
                        padding: "10px 0",
                      }}
                      error={
                        !!(formik.touched.password && formik.errors.password)
                      }
                      fullWidth
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="password"
                      value={formik.values.password}
                      variant="standard"
                    />
                  </Stack>
                  <Stack>
                    <span
                      style={{
                        fontFamily: "tmwCustomFont",
                        fontSize: 13,
                        color: "black",
                        letterSpacing: 3,
                        fontWeight: 500,
                        userSelect: "none",
                        textTransform: "uppercase",
                      }}
                    >
                      Password Confirmation
                    </span>
                    <TextField
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottom: "1px solid #E6E6E6",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "2px solid #333",
                        },
                        padding: "10px 0",
                      }}
                      error={
                        !!(
                          formik.touched.passwordConfirm &&
                          formik.errors.passwordConfirm
                        )
                      }
                      fullWidth
                      helperText={
                        formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm
                      }
                      name="passwordConfirm"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="password"
                      value={formik.values.passwordConfirm}
                      variant="standard"
                    />
                  </Stack>
                </Stack>
                <Box sx={{ padding: "15px 0", mt: 3 }}>
                  <CustomButton
                    label="Submit"
                    disabled={formik.isSubmitting}
                    type="submit"
                  />
                </Box>
              </form>
            </CardContent>
          </Card>
        </div>
      </>
    )
  );
};

export default Page;
