import { createSlice } from "@reduxjs/toolkit";
import { productAttributesThunks } from "src/thunks/product-attributes";

const productAttributesState = {
  // Linesheets
  linesheets: [],
  linesheetProductsFromExcel: [],

  // Linesheet Loading/Redirect
  linesheetsLoading: false,

  // Linesheet Products
  linesheetProducts: [],

  // Linesheet Products Loading
  updateLinesheetProductsLoading: false,

  // Linesheet Products Refresh
  refreshLinesheetProductsList: false,

  // Import Excel
  linesheetProductsImportLoading: false,
  importExcelErrors: null,

  // Manufacturers Autocomplete data
  manufacturersAutocompleteData: [],
  manufacturersAutocompleteDataLoading: false,

  // Manufacturers
  createManufacturerLoading: false,
  refreshManufacturers: false,
};

const reducers = {
  // Linesheets List
  linesheetListLoading(state) {
    if (!state.linesheetsLoading) {
      state.linesheetsLoading = true;
    }
  },
  linesheetListReceived(state, action) {
    if (state.linesheetsLoading) {
      state.linesheetsLoading = false;
      state.linesheets = action.payload;
    }
  },

  // Linesheet Products
  linesheetProductsListLoading(state) {
    if (!state.linesheetProductsLoading) {
      state.linesheetProductsLoading = true;
    }
  },
  linesheetProductsListReceived(state, action) {
    if (state.linesheetProductsLoading) {
      state.linesheetProductsLoading = false;

      const linesheetProductsRows = action.payload.map((item) => {
        return {
          ...item,
          created_by: item.user?.email,
          created_at: item.createdAt,
          updated_at: item.updatedAt,
          user: item?.user,
        };
      });

      state.linesheetProducts = linesheetProductsRows;
    }
  },

  // Linesheet Product - Edit product (row)
  editLinesheetProductRow(state, action) {
    state.linesheetProducts = [...state.linesheetProducts].map((row) => {
      if (row.id === action.payload.id) {
        // Set edit flag to true
        if (!action.payload.new) {
          action.payload.edit = true;
        }

        return action.payload;
      }

      return row;
    });
  },

  // Linesheet Products Update --Table Bulk--
  linesheetProductsUpdateLoading(state) {
    if (!state.updateLinesheetProductsLoading) {
      state.updateLinesheetProductsLoading = true;
    }
  },
  linesheetProductsUpdateLoadingReceived(state, action) {
    if (state.updateLinesheetProductsLoading) {
      state.updateLinesheetProductsLoading = false;
      state.refreshLinesheetProductsList = true;
    }
  },

  // Refresh Linesheet Products Reset
  linesheetProductsRefreshReset(state) {
    state.refreshLinesheetProductsList = false;
  },

  // Import Excel Errors Reset
  linesheetProductsImportErrorReset(state) {
    state.importExcelErrors = null;
  },

  // Product Attributes Manufacturers Autocomplete Data
  manufacturersAutocompleteDataLoading(state) {
    if (!state.manufacturersAutocompleteDataLoading) {
      state.manufacturersAutocompleteDataLoading = true;
    }
  },
  manufacturersAutocompleteDataReceived(state, action) {
    if (state.manufacturersAutocompleteDataLoading) {
      state.manufacturersAutocompleteDataLoading = false;
      state.manufacturersAutocompleteData = action.payload;
    }
  },

  // Manufacturer
  createManufacturerLoading(state) {
    if (!state.createManufacturerLoading) {
      state.createManufacturerLoading = true;
    }
  },
  createManufacturerReceived(state) {
    if (state.createManufacturerLoading) {
      state.createManufacturerLoading = false;
      state.refreshManufacturers = true;
    }
  },

  // Reset Manufacturers Refresh
  resetManufacturersRefresh(state) {
    state.refreshManufacturers = false;
  },
};

const extraReducers = {
  // Upload Linesheet Products from Excel
  [productAttributesThunks.uploadLinesheetProductsFromExcel.pending]: (
    state,
    action
  ) => {
    state.linesheetProductsImportLoading = true;
  },
  [productAttributesThunks.uploadLinesheetProductsFromExcel.fulfilled]: (
    state,
    action
  ) => {
    state.linesheetProductsImportLoading = false;
    state.linesheetProductsFromExcel = action.payload;
    state.refreshLinesheetProductsList = true;
  },
  [productAttributesThunks.uploadLinesheetProductsFromExcel.rejected]: (
    state,
    action
  ) => {
    state.linesheetProductsImportLoading = false;
    state.importExcelErrors = action.payload.error.details;
  },
};

export const slice = createSlice({
  name: "product-attributes",
  initialState: productAttributesState,
  reducers,
  extraReducers,
});

export const { reducer } = slice;
