import * as Yup from "yup";
import { useFormik } from "formik";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  FormLabel,
  Link,
  Stack,
  TextField,
  Alert,
} from "@mui/material";
import { RouterLink } from "src/components/router-link";
import { Seo } from "src/components/seo";
import { useAuth } from "src/hooks/use-auth";
import { useMounted } from "src/hooks/use-mounted";
import { usePageView } from "src/hooks/use-page-view";
import { useRouter } from "src/hooks/use-router";
import { useSearchParams } from "src/hooks/use-search-params";
import { paths } from "src/paths";
import { useParams } from "react-router-dom";
import { useEffect, useContext } from "react";
import axios from "axios";
import deployEnvironment from "src/deployEnvironment";
import { useNavigate } from "react-router-dom";
import { Layout as AuthLayout } from "src/layouts/auth/classic-layout";
import { AlertContext } from "../../../components/alert/AlertContext";
import {
  CustomButton,
  CustomTextField,
  CustomTypography,
} from "src/components/inputs";

const API = deployEnvironment;

const getInitialValues = (username) => ({
  password: "",
  passwordConfirm: "",
  submit: null,
});

const validationSchema = Yup.object({
  password: Yup.string()
    .min(7, "Must be at least 7 characters")
    .max(255)
    .required("Required"),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Required"),
});

const CreatePasswordPage = ({ isBrand }) => {
  const isMounted = useMounted();
  const router = useRouter();
  const searchParams = useSearchParams();
  const username = searchParams.get("username") || undefined;
  const params = useParams();
  const navigate = useNavigate();

  const { showAlert } = useContext(AlertContext);

  // Function to send the token to the backend API
  const verifyTokenValidity = async () => {
    const token = params?.token;

    if (!token) navigate("/404", { replace: true });

    const result = await axios
      .post(`${API}/api/validate-password-creation/${token}`, {
        isBrand,
      })
      .catch((error) => {
        navigate("/401", { replace: true });
        throw new Error("This link has expired");
      });

    if (result.data.status === 404) {
      navigate("/401", { replace: true });
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getInitialValues(username),
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const token = params?.token;
        const password = values?.password;

        if (!password) return;

        // Call backend API
        const result = await axios.post(`${API}/api/create-password/${token}`, {
          password,
          isBrand,
        });

        if (isMounted()) {
          showAlert("Password created successfully!", "success");
          router.push(paths.auth.jwt.login);
        }
      } catch (err) {
        console.error(err);

        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    },
  });

  useEffect(() => {
    verifyTokenValidity();
  }, []);

  usePageView();

  return (
    <AuthLayout>
      <Seo title="Create Password" />
      <div>
        <Card
          elevation={16}
          style={{
            borderRadius: 0,
            boxShadow: "none",
            border: "1px solid #E6E6E6",
            width: "400px",
            padding: "15px",
            paddingTop: "25px",
          }}
        >
          <CustomTypography
            message="Create Password"
            title
            style={{
              paddingLeft: "10px",
            }}
          />
          <CardContent>
            <form noValidate onSubmit={formik.handleSubmit}>
              <Stack spacing={4} style={{ marginTop: "25px" }}>
                <CustomTextField
                  touched={formik.touched.password}
                  errors={formik.errors.password}
                  fullWidth
                  helperText={formik.touched.password && formik.errors.password}
                  label="Password"
                  name="password"
                  onBlur={formik.handleBlur}
                  handleChange={formik.handleChange}
                  type="password"
                  value={formik.values.password}
                />
                <Stack>
                  <span
                    style={{
                      fontFamily: "tmwCustomFont",
                      fontSize: 13,
                      color: "black",
                      letterSpacing: 3,
                      fontWeight: 500,
                      userSelect: "none",
                      textTransform: "uppercase",
                    }}
                  >
                    Password Confirmation
                  </span>
                  <TextField
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "1px solid #E6E6E6",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "2px solid #333",
                      },
                      padding: "10px 0",
                    }}
                    error={
                      !!(
                        formik.touched.passwordConfirm &&
                        formik.errors.passwordConfirm
                      )
                    }
                    fullWidth
                    helperText={
                      formik.touched.passwordConfirm &&
                      formik.errors.passwordConfirm
                    }
                    name="passwordConfirm"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type="password"
                    value={formik.values.passwordConfirm}
                    variant="standard"
                  />
                </Stack>
              </Stack>
              {formik.errors.submit && (
                <FormHelperText error sx={{ mt: 3 }}>
                  {formik.errors.submit}
                </FormHelperText>
              )}
              <Stack style={{ marginTop: "50px" }}>
                <CustomButton
                  label="Submit"
                  disabled={formik.isSubmitting}
                  fullWidth
                  fullSize
                  type="submit"
                  variant="contained"
                />
              </Stack>
            </form>
          </CardContent>
        </Card>
      </div>
    </AuthLayout>
  );
};

export default CreatePasswordPage;
