import { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Layout as DashboardLayout } from "src/layouts/dashboard";

const IndexPage = lazy(() => import("src/pages/dashboard/index"));

// Tomorrow Portal Dashboard Routes

// Linesheets
const LinesheetsTablePage = lazy(() =>
  import("src/pages/dashboard/linesheets/tables/linesheets-table")
);
const LinesheetCreatePage = lazy(() =>
  import("src/pages/dashboard/linesheets/forms/linesheet-create")
);
const LinesheetEditPage = lazy(() =>
  import("src/pages/dashboard/linesheets/forms/linesheet-edit")
);
// const LinesheetManageProductsPage = lazy(() => import('src/pages/dashboard/linesheet-items/tables/edit-table/linesheet-items-edit-table'));
const LinesheetManageProductsPage = lazy(() =>
  import("src/pages/dashboard/linesheet-items/tables/linesheet-items-table")
);
const LinesheetCreateItem = lazy(() =>
  import("src/pages/dashboard/linesheet-items/forms/linesheet-item-create")
);
const LinesheetEditItem = lazy(() =>
  import("src/pages/dashboard/linesheet-items/forms/linesheet-item-edit")
);

// Price Management
const PriceManagementLinesheetTablePage = lazy(() =>
  import(
    "src/pages/dashboard/price-management/tables/linesheets/linesheets-table"
  )
);
const PriceManagementLinesheetItemsTablePage = lazy(() =>
  import(
    "src/pages/dashboard/price-management/tables/linesheet-items/linesheet-items-table"
  )
);

// Product Attributes (Linesheet Items)
const ProductAttributesLinesheetTablePage = lazy(() =>
  import(
    "src/pages/dashboard/product-attributes/tables/linesheets/linesheets-table"
  )
);

const ProductAttributesLinesheetProductsTablePage = lazy(() =>
  import(
    "src/pages/dashboard/product-attributes/tables/linesheet-products/linesheet-products-table"
  )
);

// Purchase Orders
const PurchaseOrdersTablePage = lazy(() =>
  import("src/pages/dashboard/purchase-orders/tables/purchase-orders-table")
);

const PurchaseOrderItemsTablePage = lazy(() =>
  import(
    "src/pages/dashboard/purchase-order-items/tables/purchase-order-items-table"
  )
);

const PurchaseOrderItemViewPage = lazy(() =>
  import(
    "src/pages/dashboard/purchase-order-items/forms/purchase-order-item-view"
  )
);

// Price Setup
const PriceSetupFinanceForm = lazy(() =>
  import("src/sections/dashboard/price-setup/finances/price-setup-finance")
);

const PriceSetupOperationsForm = lazy(() =>
  import("src/sections/dashboard/price-setup/operations/price-setup-operation")
);

const PriceSetupSalesForm = lazy(() =>
  import("src/sections/dashboard/price-setup/sales/price-setup-sale")
);

const LinesheetItemManageColourVariants = lazy(() =>
  import("src/pages/dashboard/linesheet-items/forms/manage-colour-variants")
);

// Linesheet Sync To Netsuite History (Linesheet Table)
const LinesheetSyncToNetsuiteHistory = lazy(() =>
  import(
    "src/pages/dashboard/netsuite-sync-history/linesheets/netsuite-sync-history"
  )
);

// Linesheet Sync To Netsuite History (Price Management Table)
const LinesheetSyncToNetsuiteHistoryPriceManagement = lazy(() =>
  import(
    "src/pages/dashboard/netsuite-sync-history/price-management/netsuite-sync-history"
  )
);

// Linesheet Netsuite Items (Table)
const LinesheetNetsuiteItems = lazy(() =>
  import(
    "src/pages/dashboard/linesheet-netsuite-items/tables/linesheet-netsuite-items-table"
  )
);

// Linesheet Settings
const LinesheetSettingsBlankTemplate = lazy(() =>
  import(
    "src/sections/dashboard/linesheet-settings/blank-template/linesheet-settings-blank-template"
  )
);

const LinesheetSettingsZedonkMappings = lazy(() =>
  import(
    "src/sections/dashboard/linesheet-settings/zedonk-mappings/linesheet-settings-zedonk-mappings"
  )
);

// Client Management Companies
const ClientManagementCompanies = lazy(() =>
  import("src/sections/dashboard/client-management/companies")
);

const ClientManagementCompanyDetails = lazy(() =>
  import("src/sections/dashboard/client-management/companies/company-details")
);

// Client Management Contacts
const ClientManagementContacts = lazy(() =>
  import("src/sections/dashboard/client-management/contacts")
);

const ClientManagementContactsDetails = lazy(() =>
  import("src/sections/dashboard/client-management/contacts/contact-details")
);

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <IndexPage />,
      },
      {
        path: "linesheets",
        children: [
          {
            index: true,
            element: <LinesheetsTablePage />,
          },
          {
            path: "create",
            element: <LinesheetCreatePage />,
          },
          {
            path: "edit",
            children: [
              {
                path: ":linesheetId",
                element: <LinesheetEditPage />,
              },
            ],
          },
          {
            path: ":linesheetId",
            children: [
              {
                path: "manage-products",
                element: <LinesheetManageProductsPage />,
              },
              {
                path: "manage-products/create-product",
                element: <LinesheetCreateItem />,
              },
              {
                path: "manage-products/edit/:linesheetItemId",
                element: <LinesheetEditItem />,
              },
              {
                path: "manage-products/:linesheetItemId/manage-colour-variants",
                element: <LinesheetItemManageColourVariants />,
              },
              {
                path: "netsuite-sync-history",
                element: <LinesheetSyncToNetsuiteHistory />,
              },
              {
                path: "netsuite-items",
                element: <LinesheetNetsuiteItems />,
              },
            ],
          },
        ],
      },
      {
        path: "price-management",
        children: [
          {
            index: true,
            element: <PriceManagementLinesheetTablePage />,
          },
          {
            path: ":linesheetId",
            children: [
              {
                path: "prices",
                element: <PriceManagementLinesheetItemsTablePage />,
              },
              {
                path: "netsuite-sync-history",
                element: <LinesheetSyncToNetsuiteHistoryPriceManagement />,
              },
            ],
          },
        ],
      },
      {
        path: "product-attributes",
        children: [
          {
            index: true,
            element: <ProductAttributesLinesheetTablePage />,
          },
          {
            path: ":linesheetId",
            children: [
              {
                path: "manage",
                element: <ProductAttributesLinesheetProductsTablePage />,
              },
            ],
          },
        ],
      },
      {
        path: "purchase-orders",
        children: [
          {
            index: true,
            element: <PurchaseOrdersTablePage />,
          },
          {
            path: ":purchaseOrderId",
            children: [
              {
                path: "details",
                element: <PurchaseOrderItemsTablePage />,
              },
              {
                path: "purchase-order-item/:purchaseOrderItemId/view",
                element: <PurchaseOrderItemViewPage />,
              },
            ],
          },
        ],
      },
      {
        path: "price-setup/finance",
        children: [
          {
            index: true,
            element: <PriceSetupFinanceForm />,
          },
        ],
      },
      {
        path: "price-setup/operations",
        children: [
          {
            index: true,
            element: <PriceSetupOperationsForm />,
          },
        ],
      },
      {
        path: "price-setup/sales",
        children: [
          {
            index: true,
            element: <PriceSetupSalesForm />,
          },
        ],
      },
      {
        path: "linesheet-settings/blank-template",
        children: [
          {
            index: true,
            element: <LinesheetSettingsBlankTemplate />,
          },
        ],
      },
      {
        path: "linesheet-settings/zedonk-mappings",
        children: [
          {
            index: true,
            element: <LinesheetSettingsZedonkMappings />,
          },
        ],
      },
      {
        path: "client-management/companies",
        children: [
          {
            index: true,
            element: <ClientManagementCompanies />,
          },
          {
            path: ":customer_id/:contact_id",
            children: [
              {
                path: "details",
                element: <ClientManagementCompanyDetails />,
              },
            ],
          },
          {
            path: ":customer_id",
            children: [
              {
                path: "details",
                element: <ClientManagementCompanyDetails />,
              },
            ],
          },
        ],
      },
      {
        path: "client-management/contacts",
        children: [
          {
            index: true,
            element: <ClientManagementContacts />,
          },
          {
            path: ":customer_id",
            children: [
              {
                path: "details",
                element: <ClientManagementContactsDetails />,
              },
            ],
          },
        ],
      },
      // {
      //   path: 'academy',
      //   children: [
      //     {
      //       index: true,
      //       element: <AcademyDashboardPage />
      //     },
      //     {
      //       path: 'courses',
      //       children: [
      //         {
      //           path: ':courseId',
      //           element: <AcademyCoursePage />
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   path: 'blog',
      //   children: [
      //     {
      //       index: true,
      //       element: <BlogPostListPage />
      //     },
      //     {
      //       path: 'create',
      //       element: <BlogPostCreatePage />
      //     },
      //     {
      //       path: ':postId',
      //       element: <BlogPostDetailPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'customers',
      //   children: [
      //     {
      //       index: true,
      //       element: <CustomerListPage />
      //     },
      //     {
      //       path: ':customerId',
      //       element: <CustomerDetailPage />
      //     },
      //     {
      //       path: ':customerId/edit',
      //       element: <CustomerEditPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'invoices',
      //   children: [
      //     {
      //       index: true,
      //       element: <InvoiceListPage />
      //     },
      //     {
      //       path: ':invoiceId',
      //       element: <InvoiceDetailPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'jobs',
      //   children: [
      //     {
      //       index: true,
      //       element: <JobBrowsePage />
      //     },
      //     {
      //       path: 'create',
      //       element: <JobCreatePage />
      //     },
      //     {
      //       path: 'companies',
      //       children: [
      //         {
      //           path: ':companyId',
      //           element: <CompanyDetailPage />
      //         }
      //       ]
      //     }
      //   ]
      // },
      // {
      //   path: 'logistics',
      //   children: [
      //     {
      //       index: true,
      //       element: <LogisticsDashboardPage />
      //     },
      //     {
      //       path: 'fleet',
      //       element: <LogisticsFleetPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'orders',
      //   children: [
      //     {
      //       index: true,
      //       element: <OrderListPage />
      //     },
      //     {
      //       path: ':orderId',
      //       element: <OrderDetailPage />
      //     }
      //   ]
      // },
      // {
      //   path: 'products',
      //   children: [
      //     {
      //       index: true,
      //       element: <ProductListPage />
      //     },
      //     {
      //       path: 'create',
      //       element: <ProductCreatePage />
      //     }
      //   ]
      // },
      // {
      //   path: 'social',
      //   children: [
      //     {
      //       path: 'feed',
      //       element: <SocialFeedPage />
      //     },
      //     {
      //       path: 'profile',
      //       element: <SocialProfilePage />
      //     }
      //   ]
      // },
      // {
      //   path: 'account',
      //   element: <AccountPage />
      // },
      // {
      //   path: 'blank',
      //   element: <BlankPage />
      // },
      // {
      //   path: 'calendar',
      //   element: <CalendarPage />
      // },
      // {
      //   path: 'chat',
      //   element: <ChatPage />
      // },
      // {
      //   path: 'crypto',
      //   element: <CryptoPage />
      // },
      // {
      //   path: 'ecommerce',
      //   element: <EcommercePage />
      // },
      // {
      //   path: 'file-manager',
      //   element: <FileManagerPage />
      // },
      // {
      //   path: 'kanban',
      //   element: <KanbanPage />
      // },
      // {
      //   path: 'mail',
      //   element: <MailPage />
      // }
    ],
  },
];
