import { createSlice } from "@reduxjs/toolkit";

const priceSetupState = {
  //
  // Finances
  //
  priceSetupFinances: [],
  updatedPriceSetupFinances: {},

  // Finances Loading/Redirect
  priceSetupFinancesListLoading: false,
  priceSetupFinancesUpdateLoading: false,

  // Finances Refresh
  updatedPriceSetupFinancesRefresh: false,

  //
  // Operations
  //
  priceSetupOperations: [],
  updatedPriceSetupOperations: {},

  // Operations Loading/Redirect
  priceSetupOperationsListLoading: false,
  priceSetupOperationsUpdateLoading: false,

  // Operations Refresh
  updatedPriceSetupOperationsRefresh: false,

  //
  // Sales
  //
  priceSetupSales: [],
  updatedPriceSetupSales: {},

  // Sales Loading/Redirect
  priceSetupSalesListLoading: false,
  priceSetupSalesUpdateLoading: false,

  // Sales Refresh
  updatedPriceSetupSalesRefresh: false,
};

const reducers = {
  //
  // Finances
  //

  priceSetupFinancesListLoading(state) {
    if (!state.priceSetupFinancesListLoading) {
      state.priceSetupFinancesListLoading = true;
    }
  },
  priceSetupFinancesListReceived(state, action) {
    if (state.priceSetupFinancesListLoading) {
      state.priceSetupFinancesListLoading = false;
      state.priceSetupFinances = action.payload;
    }
  },

  // Update Finance
  priceSetupFinancesUpdateLoading(state) {
    if (!state.priceSetupFinancesUpdateLoading) {
      state.priceSetupFinancesUpdateLoading = true;
    }
  },
  priceSetupFinancesUpdateReceived(state, action) {
    if (state.priceSetupFinancesUpdateLoading) {
      state.priceSetupFinancesUpdateLoading = false;
      state.updatedPriceSetupFinances = action.payload;
      state.updatedPriceSetupFinancesRefresh = true;
    }
  },
  // Reset Refresh Finance
  resetRefreshPriceSetupFinances(state) {
    state.updatedPriceSetupFinancesRefresh = false;
  },

  //
  // Operations
  //

  priceSetupOperationsListLoading(state) {
    if (!state.priceSetupOperationsListLoading) {
      state.priceSetupOperationsListLoading = true;
    }
  },
  priceSetupOperationsListReceived(state, action) {
    if (state.priceSetupOperationsListLoading) {
      state.priceSetupOperationsListLoading = false;
      state.priceSetupOperations = action.payload;
    }
  },

  // Update Operation
  priceSetupOperationsUpdateLoading(state) {
    if (!state.priceSetupOperationsUpdateLoading) {
      state.priceSetupOperationsUpdateLoading = true;
    }
  },
  priceSetupOperationsUpdateReceived(state, action) {
    if (state.priceSetupOperationsUpdateLoading) {
      state.priceSetupOperationsUpdateLoading = false;
      state.updatedPriceSetupOperations = action.payload;
      state.updatedPriceSetupOperationsRefresh = true;
    }
  },
  // Reset Refresh Operation
  resetRefreshPriceSetupOperations(state) {
    state.updatedPriceSetupOperationsRefresh = false;
  },

  //
  // Sales
  //

  priceSetupSalesListLoading(state) {
    if (!state.priceSetupSalesListLoading) {
      state.priceSetupSalesListLoading = true;
    }
  },
  priceSetupSalesListReceived(state, action) {
    if (state.priceSetupSalesListLoading) {
      state.priceSetupSalesListLoading = false;
      state.priceSetupSales = action.payload;
    }
  },

  // Update Sale
  priceSetupSalesUpdateLoading(state) {
    if (!state.priceSetupSalesUpdateLoading) {
      state.priceSetupSalesUpdateLoading = true;
    }
  },
  priceSetupSalesUpdateReceived(state, action) {
    if (state.priceSetupSalesUpdateLoading) {
      state.priceSetupSalesUpdateLoading = false;
      state.updatedPriceSetupSales = action.payload;
      state.updatedPriceSetupSalesRefresh = true;
    }
  },
  // Reset Refresh Sale
  resetRefreshPriceSetupSales(state) {
    state.updatedPriceSetupSalesRefresh = false;
  },
};

export const slice = createSlice({
  name: "price-setup",
  initialState: priceSetupState,
  reducers,
});

export const { reducer } = slice;
