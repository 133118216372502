import { Outlet } from "react-router-dom";
import HomePage from "src/pages";
import Error401Page from "src/pages/401";
import Error404Page from "src/pages/404";
import Error500Page from "src/pages/500";
import ContactPage from "src/pages/contact";
import CheckoutPage from "src/pages/checkout";
import PricingPage from "src/pages/pricing";
import { authRoutes } from "./auth";
import { authDemoRoutes } from "./auth-demo";
import { componentsRoutes } from "./components";
import { dashboardRoutes } from "./dashboard";
import { bookingProcessRoutes } from "./booking-process";
import { customersRoutes } from "./customers";
import { lazy } from "react";
import { IssuerGuard } from "src/guards/issuer-guard";
import { GuestGuard } from "src/guards/guest-guard";
import { Layout } from "src/layouts/auth/classic-layout";
import { Issuer } from "src/utils/auth";
import CreatePasswordPage from "src/pages/auth/jwt/reset-password.js";
import AutoLoginPage from "src/pages/auth/jwt/auto-login";
import ForgotPasswordPage from "src/pages/auth/jwt/forgot-password";
import ChangePasswordPage from "src/pages/auth/jwt/change-password";
const JwtLoginPage = lazy(() => import("src/pages/auth/jwt/login"));

export const routes = [
  {
    element: (
      <IssuerGuard issuer={Issuer.JWT}>
        <GuestGuard>
          <Layout>
            <Outlet />
          </Layout>
        </GuestGuard>
      </IssuerGuard>
    ),
    children: [
      {
        index: true,
        element: <JwtLoginPage />,
      },
    ],
  },
  ...dashboardRoutes,
  ...bookingProcessRoutes,
  ...customersRoutes,
  {
    path: "create-password/:token",
    element: <CreatePasswordPage />,
  },
  {
    path: "brands-create-password/:token",
    element: <CreatePasswordPage isBrand={true} />,
  },
  {
    path: "auto-login/:jwt",
    element: <AutoLoginPage link="/dashboard/linesheets/create" />,
  },
  {
    path: "/auth/jwt/forgot-password",
    element: (
      <Layout>
        <ForgotPasswordPage />
      </Layout>
    ),
  },
  {
    path: "/auth/jwt/change-password/:token",
    element: (
      <Layout>
        <ChangePasswordPage />
      </Layout>
    ),
  },
  {
    path: "401",
    element: <Error401Page />,
  },
  {
    path: "500",
    element: <Error500Page />,
  },
  {
    path: "*",
    element: <Error404Page />,
  },
];
