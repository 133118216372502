export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
  },
  nav: {
    academy: "nav.academy",
    account: "nav.account",
    linesheets: "nav.linesheets",
    auth: "nav.auth",
    blog: "nav.blog",
    browse: "nav.browse",
    calendar: "nav.calendar",
    chat: "nav.chat",
    checkout: "nav.checkout",
    concepts: "nav.concepts",
    contact: "nav.contact",
    course: "nav.course",
    create: "nav.create",
    crypto: "nav.crypto",
    customers: "nav.customers",
    dashboard: "nav.dashboard",
    details: "nav.details",
    ecommerce: "nav.ecommerce",
    edit: "nav.edit",
    error: "nav.error",
    feed: "nav.feed",
    fileManager: "nav.fileManager",
    files: "nav.files",
    finance: "nav.finance",
    fleet: "nav.fleet",
    forgotPassword: "nav.forgotPassword",
    invoiceList: "nav.invoices",
    jobList: "nav.jobList",
    kanban: "nav.kanban",
    list: "nav.list",
    login: "nav.login",
    logistics: "nav.logistics",
    mail: "nav.mail",
    management: "nav.management",
    orderList: "nav.orders",
    overview: "nav.overview",
    pages: "nav.pages",
    postCreate: "nav.postCreate",
    postDetails: "nav.postDetails",
    postList: "nav.postList",
    pricing: "nav.pricing",
    productList: "nav.products",
    profile: "nav.profile",
    register: "nav.register",
    resetPassword: "nav.resetPassword",
    socialMedia: "nav.socialMedia",
    verifyCode: "nav.verifyCode",
    settings: "nav.settings",
  },
  topNav: {
    logout: {
      title: "topNav.logout.title",
      logoutButton: "topNav.logout.logoutButton",
      cancelButton: "topNav.logout.cancelButton",
    },
  },
  linesheets: {
    newLinesheetButton: "linesheets.newLinesheetButton",
    editLinesheetActionButton: "linesheets.editLinesheetActionButton",
    manageProductsActionButton: "linesheets.manageProductsActionButton",
    table: {
      name: "linesheets.table.name",
      status: "linesheets.table.status",
      brand: "linesheets.table.brand",
      season: "linesheets.table.season",
      category: "linesheets.table.category",
      noOfStyles: "linesheets.table.noOfStyles",
      inboundDelivery: "linesheets.table.inboundDelivery",
      outboundDelivery: "linesheets.table.outboundDelivery",
      windowStart: "linesheets.table.windowStart",
      windowEnd: "linesheets.table.windowEnd",
      syncToNetSuite: "linesheets.table.syncToNetSuite",
      syncToJoor: "linesheets.table.syncToJoor",
      inactive: "linesheets.table.inactive",
    },
    createLinesheet: {
      linesheets: "linesheets.createLinesheet.linesheet",
      title: "linesheets.createLinesheet.title",
      submitButton: "linesheets.createLinesheet.submitButton",
      cancelButton: "linesheets.createLinesheet.cancelButton",
    },
    editLinesheet: {
      linesheets: "linesheets.editLinesheet.linesheet",
      title: "linesheets.editLinesheet.title",
      submitButton: "linesheets.editLinesheet.submitButton",
      cancelButton: "linesheets.editLinesheet.cancelButton",
    },
    newLinesheetItemButton: "linesheets.newLinesheetItemButton",
    saveChangesLinesheetItemButton: "linesheets.saveChangesLinesheetItemButton",
    importFileButton: "linesheets.importFileButton",
    exportCSVButton: "linesheets.exportCSVButton",
    exportExcelButton: "linesheets.exportExcelButton",
    importZedonkFileButton: "linesheets.importZedonkFileButton",
    deleteLinesheetActionButton: "linesheets.deleteLinesheetActionButton",
    deleteProductActionButton: "linesheets.deleteProductActionButton",
    deleteColourVariantActionButton:
      "linesheets.deleteColourVariantActionButton",
    duplicateProductActionButton: "linesheets.duplicateProductActionButton",
    editProductActionButton: "linesheets.editProductActionButton",
    manageColourVariantActionButton:
      "linesheets.manageColourVariantActionButton",
    manageProductsTable: {
      season: "linesheets.manageProducts.table.season",
      brand: "linesheets.manageProducts.table.brand",
      netsuiteStyleCode: "linesheets.manageProducts.table.netsuiteStyleCode",
      styleCode: "linesheets.manageProducts.table.styleCode",
      styleName: "linesheets.manageProducts.table.styleName",
      styleDescription: "linesheets.manageProducts.table.styleDescription",
      gender: "linesheets.manageProducts.table.gender",
      productCategory: "linesheets.manageProducts.table.productCategory",
      productSubCategory: "linesheets.manageProducts.table.productSubCategory",
      colourDescription: "linesheets.manageProducts.table.colourDescription",
      primaryColour: "linesheets.manageProducts.table.primaryColour",
      secondaryColour: "linesheets.manageProducts.table.secondaryColour",
      netsuiteColourCode: "linesheets.manageProducts.table.netsuiteColourCode",
      materialComposition:
        "linesheets.manageProducts.table.materialComposition",
      customsClassification:
        "linesheets.manageProducts.table.customsClassification",
      commodityCode: "linesheets.manageProducts.table.commodityCode",
      countryOfOrigin: "linesheets.manageProducts.table.countryOfOrigin",
      sizeRunCategory: "linesheets.manageProducts.table.sizeRunCategory",
      fullSizeRun: "linesheets.manageProducts.table.fullSizeRun",
      sizesAvailableWithinFullSizeRun:
        "linesheets.manageProducts.table.sizesAvailableWithinFullSizeRun",
      manufacturer: "linesheets.manageProducts.table.manufacturer",
      linesheetName: "linesheets.manageProducts.table.linesheetName",
      inboundDelivery: "linesheets.manageProducts.table.inboundDelivery",
      inboundDeliveryOpen:
        "linesheets.manageProducts.table.inboundDeliveryOpen",
      inboundDeliveryClose:
        "linesheets.manageProducts.table.inboundDeliveryClose",
      outboundDelivery: "linesheets.manageProducts.table.inboundDelivery",
      outboundDeliveryOpen:
        "linesheets.manageProducts.table.outboundDeliveryOpen",
      outboundDeliveryClose:
        "linesheets.manageProducts.table.outboundDeliveryClose",
      brandWholesalePriceCurrency:
        "linesheets.manageProducts.table.brandWholesalePriceCurrency",
      brandWholesalePrice:
        "linesheets.manageProducts.table.brandWholesalePrice",
      purchasePriceCurrency:
        "linesheets.manageProducts.table.purchasePriceCurrency",
      purchasePrice: "linesheets.manageProducts.table.purchasePrice",
      // Repeatable
      priceList1: "linesheets.manageProducts.table.priceList1",
      currency1: "linesheets.manageProducts.table.currency1",
      purchasePrice1: "linesheets.manageProducts.table.purchasePrice1",
      wholesalePrice1: "linesheets.manageProducts.table.wholesalePrice1",
      retailPrice1: "linesheets.manageProducts.table.retailPrice1",
    },
    deleteLinesheetModal: {
      title: "linesheets.manageLinesheets.deleteModal.title",
      message: "linesheets.manageLinesheets.deleteModal.message",
      submitButtonLabel:
        "linesheets.manageLinesheets.deleteModal.submitButtonLabel",
      cancelButtonLabel:
        "linesheets.manageLinesheets.deleteModal.cancelButtonLabel",
    },
    deleteProductModal: {
      title: "linesheets.manageProducts.deleteModal.title",
      message: "linesheets.manageProducts.deleteModal.message",
      submitButtonLabel:
        "linesheets.manageProducts.deleteModal.submitButtonLabel",
      cancelButtonLabel:
        "linesheets.manageProducts.deleteModal.cancelButtonLabel",
    },
    confirmModal: {
      title: "linesheets.manageProducts.confirmModal.title",
      message: "linesheets.manageProducts.confirmModal.message",
      submitButtonLabel:
        "linesheets.manageProducts.confirmModal.submitButtonLabel",
      okButtonLabel: "linesheets.manageProducts.confirmModal.okButtonLabel",
      cancelButtonLabel:
        "linesheets.manageProducts.confirmModal.cancelButtonLabel",
      errors: "linesheets.manageProducts.confirmModal.errors",
    },
    confirmLinesheetButton: "linesheets.confirmLinesheetButton",
    approveModal: {
      title: "linesheets.manageProducts.approveModal.title",
      message: "linesheets.manageProducts.approveModal.message",
      submitButtonLabel:
        "linesheets.manageProducts.approveModal.submitButtonLabel",
      okButtonLabel: "linesheets.manageProducts.approveModal.okButtonLabel",
      cancelButtonLabel:
        "linesheets.manageProducts.approveModal.cancelButtonLabel",
      errors: "linesheets.manageProducts.approveModal.errors",
    },
    approveLinesheetButton: "linesheets.approveLinesheetButton",
    importModal: {
      title: "tokens.linesheets.importModal.title",
      message: "tokens.linesheets.importModal.message",
      importantMessage: "tokens.linesheets.importModal.importantMessage",
      submitButtonLabel: "linesheets.importModal.submitButtonLabel",
    },
    importZedonkModal: {
      title: "tokens.linesheets.importZedonk.title",
      message: "tokens.linesheets.importZedonk.message",
      importantMessage: "tokens.linesheets.importZedonk.importantMessage",
      submitButtonLabel: "linesheets.importZedonk.submitButtonLabel",
    },
    importErrorModal: {
      title: "tokens.linesheets.importErrorModal.title",
      message: "tokens.linesheets.importErrorModal.message",
      submitButtonLabel: "linesheets.importErrorModal.submitButtonLabel",
    },
    deleteColourVariantModal: {
      title: "linesheets.colourVariant.deleteModal.title",
      message: "linesheets.colourVariant.deleteModal.message",
      submitButtonLabel:
        "linesheets.colourVariant.deleteModal.submitButtonLabel",
      cancelButtonLabel:
        "linesheets.colourVariant.deleteModal.cancelButtonLabel",
    },
    newColourVariantModal: {
      title: "linesheets.colourVariant.newModal.title",
      message: "linesheets.colourVariant.newModal.message",
      submitButtonLabel: "linesheets.colourVariant.newModal.submitButtonLabel",
      cancelButtonLabel: "linesheets.colourVariant.newModal.cancelButtonLabel",
    },
    redirectColourVariantsModal: {
      title: "linesheets.redirectToColourVariants.modal.title",
      message: "linesheets.redirectToColourVariants.modal.message",
      submitButtonLabel:
        "linesheets.redirectToColourVariants.modal.submitButtonLabel",
      cancelButtonLabel:
        "linesheets.redirectToColourVariants.modal.cancelButtonLabel",
    },
  },
  priceManagement: {
    lineModificationActionButton:
      "priceManagement.lineModificationActionButton",
    changeStatusActionButton: "priceManagement.changeStatusActionButton",
    // Linesheet Table
    table: {
      name: "priceManagement.table.name",
      priceStatus: "priceManagement.table.priceStatus",
      brand: "priceManagement.table.brand",
      season: "priceManagement.table.season",
      category: "priceManagement.table.category",
      inboundDelivery: "priceManagement.table.inboundDelivery",
      outboundDelivery: "priceManagement.table.outboundDelivery",
      windowStart: "priceManagement.table.windowStart",
      windowEnd: "priceManagement.table.windowEnd",
      syncToNetSuite: "priceManagement.table.syncToNetSuite",
      syncToJoor: "priceManagement.table.syncToJoor",
      inactive: "priceManagement.table.inactive",
    },
    calculatePricesButton: "priceManagement.calculatePricesButton",
    calculatePricesModal: {
      title: "priceManagement.calculatePricesModal.title",
      message: "priceManagement.calculatePricesModal.message",
      importantMessage: "priceManagement.calculatePricesModal.importantMessage",
      submitButtonLabel:
        "priceManagement.calculatePricesModal.submitButtonLabel",
      okButtonLabel: "priceManagement.calculatePricesModal.okButtonLabel",
      cancelButtonLabel:
        "priceManagement.calculatePricesModal.cancelButtonLabel",
    },
    approveModal: {
      title: "priceManagement.approveModal.title",
      message: "priceManagement.approveModal.message",
      submitButtonLabel: "priceManagement.approveModalsubmitButtonLabel",
      cancelButtonLabel: "priceManagement.approveModal.cancelButtonLabel",
    },
  },
  productAttributes: {
    manageProductAttributesActionButton:
      "productAttributes.manageProductAttributesActionButton",
    changeStatusActionButton: "productAttributes.changeStatusActionButton",
    // Linesheet Table
    table: {
      name: "productAttributes.table.name",
      productAttributesStatus:
        "productAttributes.table.productAttributesStatus",
      brand: "productAttributes.table.brand",
      season: "productAttributes.table.season",
      category: "productAttributes.table.category",
      inboundDelivery: "productAttributes.table.inboundDelivery",
      outboundDelivery: "productAttributes.table.outboundDelivery",
      windowStart: "productAttributes.table.windowStart",
      windowEnd: "productAttributes.table.windowEnd",
      syncToNetSuite: "productAttributes.table.syncToNetSuite",
      syncToJoor: "productAttributes.table.syncToJoor",
      inactive: "productAttributes.table.inactive",
    },
    // Linesheet Products Table
    productsTable: {
      internalId: "productAttributes.productsTable.internalId",
      name: "productAttributes.productsTable.name",
      supplierStyleCode: "productAttributes.productsTable.supplierStyleCode",
      styleDescription: "productAttributes.productsTable.styleDescription",
      purchaseDescription:
        "productAttributes.productsTable.purchaseDescription",
      tomorrowColourCode: "productAttributes.productsTable.tomorrowColourCode",
      tomorrowColourDescription:
        "productAttributes.productsTable.tomorrowColourDescription",
      matrixTomorrowColourDescription:
        "productAttributes.productsTable.matrixTomorrowColourDescription",
      size: "productAttributes.productsTable.size",
      customsClassification:
        "productAttributes.productsTable.customsClassification",
      commodityCode: "productAttributes.productsTable.commodityCode",
      eanCode: "productAttributes.productsTable.eanCode",
      manufacturerCode: "productAttributes.productsTable.manufacturerCode",
      manufacturerName: "productAttributes.productsTable.manufacturerName",
      addressCity: "productAttributes.productsTable.addressCity",
      stateCounty: "productAttributes.productsTable.stateCounty",
      zipPostCode: "productAttributes.productsTable.zipPostCode",
      country: "productAttributes.productsTable.country",
      midCode: "productAttributes.productsTable.midCode",
      composition: "productAttributes.productsTable.composition",
      compositionExtras: "productAttributes.productsTable.compositionExtras",
      washingCareInstruction:
        "productAttributes.productsTable.washingCareInstruction",
      animalsScientificName:
        "productAttributes.productsTable.animalsScientificName",
      farmRaised: "productAttributes.productsTable.farmRaised",
      animalMaterial: "productAttributes.productsTable.animalMaterial",
      animalCountryOfOrigin:
        "productAttributes.productsTable.animalCountryOfOrigin",
      dyed: "productAttributes.productsTable.dyed",
      itemWeightGross: "productAttributes.productsTable.itemWeightGross",
      itemWeightNet: "productAttributes.productsTable.itemWeightNet",
      weightUnits: "productAttributes.productsTable.weightUnits",
      countryWhenceConsigned:
        "productAttributes.productsTable.countryWhenceConsigned",
    },
    // Import Excel Modal
    importModal: {
      title: "productAttributes.importModal.title",
      message: "productAttributes.importModal.message",
      importantMessage: "productAttributes.importModal.importantMessage",
      submitButtonLabel: "productAttributes.submitButtonLabel",
    },
    // Confirm Modal
    confirmModal: {
      title: "productAttributes.confirmModal.title",
      message: "productAttributes.confirmModal.message",
      submitButtonLabel: "productAttributes.confirmModal.submitButtonLabel",
      okButtonLabel: "productAttributes.confirmModal.okButtonLabel",
      cancelButtonLabel: "productAttributes.confirmModal.cancelButtonLabel",
    },
    confirmLinesheetButton: "productAttributes.confirmLinesheetButton",
    // Approve Modal
    approveModal: {
      title: "productAttributes.approveModal.title",
      message: "productAttributes.approveModal.message",
      submitButtonLabel: "productAttributes.approveModal.submitButtonLabel",
      okButtonLabel: "productAttributes.approveModal.okButtonLabel",
      cancelButtonLabel: "productAttributes.approveModal.cancelButtonLabel",
    },
    approveLinesheetButton: "productAttributes.approveLinesheetButton",
  },
  purchaseOrders: {
    // Confirm Modal
    confirmModal: {
      title: "purchaseOrders.confirmModal.title",
      message: "purchaseOrders.confirmModal.message",
      submitButtonLabel: "purchaseOrders.confirmModal.submitButtonLabel",
      cancelButtonLabel: "purchaseOrders.confirmModal.cancelButtonLabel",
    },
    confirmPurchaseOrderButton: "purchaseOrders.confirmPurchaseOrderButton",

    // Approve Modal
    approveModal: {
      title: "purchaseOrders.approveModal.title",
      message: "purchaseOrders.approveModal.message",
      submitButtonLabel: "purchaseOrders.approveModal.submitButtonLabel",
      cancelButtonLabel: "purchaseOrders.approveModal.cancelButtonLabel",
    },
    approvePurchaseOrderButton: "purchaseOrders.approvePurchaseOrderButton",
  },
};
