import { lazy } from "react";
import BrandBookingSelect from "src/pages/booking-process/brand-booking-select";

const BookingCalendar = lazy(() =>
  import("src/pages/booking-process/booking-calendar")
);
const BookingThankYouPage = lazy(() =>
  import("src/pages/booking-process/booking-completion")
);

export const bookingProcessRoutes = [
  {
    path: "booking-process/:sc_id/:id",
    children: [
      {
        index: true,
        element: <BrandBookingSelect />,
      },
      {
        path: "calendar",
        element: <BookingCalendar />,
      },
      {
        path: "completion",
        element: <BookingThankYouPage />,
      },
    ],
  },
];
