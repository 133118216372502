import PropTypes from "prop-types";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";
import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useState } from "react";

const STORAGE_KEY = "drawerOpened";

export const Layout = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();

  const [drawerState, setDrawerState] = useState(
    window.localStorage.getItem(STORAGE_KEY) === "true"
  );
  const [drawerTransition, setDrawerTransition] = useState(true);

  const handleOpen = async () => {
    window.localStorage.setItem(STORAGE_KEY, true);
    setDrawerTransition(true);
    setDrawerState(true);
  };
  const handleClose = async () => {
    window.localStorage.setItem(STORAGE_KEY, false);
    setDrawerTransition(false);
    setDrawerState(false);
  };

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      drawerOpen={drawerState}
      handleOpen={handleOpen}
      handleClose={handleClose}
      drawerTransition={drawerTransition}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
