import React, { useState, useEffect, useRef, useCallback } from "react";
import tmwLogo from "src/images/tmw_logo.png";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import {
  Box,
  Container,
  Stack,
  useMediaQuery,
  MenuItem,
  Dialog,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // Needed for dayClick
import { CalendarToolbar } from "src/sections/dashboard/calendar/calendar-toolbar";
import Paper from "@mui/material/Paper";
import { alpha, styled } from "@mui/material/styles";
// Icons
import tmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon-black.png";
import redTmwWarningIcon from "src/icons/tmw-ui/tmw-warning-icon.png";
import CloseIcon from "@mui/icons-material/Close";
// Hooks
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-process";
import { slice } from "src/slices/booking-process";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  CustomButton,
  CustomDivider,
  CustomSelect,
  CustomTooltip,
  CustomUnsavedChangesAlert,
  CustomTextField,
} from "src/components/inputs";
import { CloseOutlined } from "@mui/icons-material";

const CalendarContainer = styled("div")(({ theme }) => ({
  "& .fc": {
    "--fc-today-bg-color": alpha(theme.palette.primary.main, 0.2),
  },
  ".fc-scrollgrid": {
    borderStyle: "none !important",
  },
  ".fc td": {
    border: "none",
  },
  ".fc th": {
    borderStyle: "none !important",
    fontWeight: "300",
    fontSize: 14,
  },
  ".fc th .fc-scrollgrid-sync-inner": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc tr": {
    borderBottom: "1px solid #E6E6E6 !important",
    borderRight: "none",
    borderLeft: "none",
  },
  ".fc .fc-highlight": {
    background: "none",
  },
  ".fc .fc-daygrid-day-top": {
    fontFamily: "tmwCustomFont",
    letterSpacing: 3,
    fontSize: 20,
    marginBottom: "-15px",
    display: "flex",
    justifyContent: "center",
  },
  ".fc .fc-daygrid-day.fc-day-today": {
    background: "none",
  },
  ".fc .fc-daygrid-day-events .fc-daygrid-event-harness": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ".fc .fc-h-event": {
    background: "none",
    margin: "17px 0 -17px 0",
    border: "none",
  },
  ".fc .fc-daygrid-day-events .fc-event-main": {
    background: "black",
    borderRadius: "50%",
    maxHeight: "8px",
    width: "8px",
  },
  ".fc .fc-daygrid-day-events .fc-event-main .fc-event-main-frame": {
    visibility: "collapse",
  },
  ".fc-scrollgrid-sync-inner": {
    fontFamily: "tmwCustomFont",
    textAlign: "right",
  },
  ".fc-daygrid-day-frame": {
    paddingTop: "5px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  textAlign: "center",
  color: theme.palette.text.secondary,
  minWidth: "75px",
  padding: "5px 25px",
  userSelect: "none",
  border: "1px solid lightgrey",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0",
}));

const BookingItem = styled("div")(({ theme }) => ({
  fontFamily: "tmwCustomFont",
  letterSpacing: 3,
  fontSize: 14,
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  textAlign: "center",
  color: "black",
  minWidth: "75px",
  padding: "5px 25px",
  userSelect: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "5px 0",
}));

export const renderLogo = (mobileView) => {
  return (
    <Stack
      sx={{
        height: "100%",
        alignItems: mobileView ? "center" : "",
      }}
    >
      <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
        <Box
          component={RouterLink}
          href={paths.index}
          sx={{
            display: "flex",
            height: 100,
            p: "4px",
            width: 100,
          }}
        >
          <img
            style={{
              margin: mobileView ? "0 0 -30px -10px" : "-25% 0 -25% 25px",
            }}
            src={tmwLogo}
          />
        </Box>
      </Stack>
    </Stack>
  );
};

export const renderRefreshAlert = () => {
  return (
    <Container maxWidth={false} style={{ display: "flex" }}>
      <CustomUnsavedChangesAlert />
    </Container>
  );
};

const renderMeetingTypeSelect = (
  meetingType,
  setMeetingType,
  currentBookings,
  currentLocation,
  mdUp,
  meetingTypeValues
) => {
  const currentBooking = currentBookings.filter(
    (booking) => booking.location === currentLocation.name
  );

  return (
    <Stack
      style={{
        margin: "35px 0",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Stack style={{ marginBottom: "5px" }}>
        <p
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: !mdUp ? 11 : 16,
            textTransform: "uppercase",
          }}
        >
          Meeting type
        </p>
      </Stack>
      <div style={{ zIndex: 2000 }}>
        <CustomSelect
          id="meeting_type_select"
          width="225px"
          value={currentBooking[0]?.meetingType || meetingType}
          onChange={(e) => setMeetingType(e.target.value)}
          disabled={!!currentBooking[0]?.meetingType}
        >
          {meetingTypeValues?.map((meetingType) => {
            return (
              <MenuItem key={meetingType} value={meetingType}>
                {meetingType}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </div>
    </Stack>
  );
};

const renderMeetingTypeChangeAlert = (setShowMeetingTypeChangeAlert, mdUp) => {
  return (
    <Stack
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: 15,
        border: "1px solid red",
        padding: "5px 0",
        minWidth: mdUp ? "350px" : "300px",
        maxWidth: mdUp ? "400px" : "340px",
        marginLeft: mdUp && "auto",
        marginRight: mdUp && "auto",
      }}
    >
      <img src={redTmwWarningIcon} style={{ width: "27px" }} />
      <p
        style={{
          width: "225px",
          fontFamily: "tmwCustomFont",
          letterSpacing: 2.5,
          fontSize: 9,
          textTransform: "uppercase",
          textAlign: "center",
          color: "red",
        }}
      >
        The meeting type values have changed for the last selected date
      </p>
      {/* {mdUp && (
        <CloseIcon
          onClick={() => setShowMeetingTypeChangeAlert(false)}
          style={{
            position: "absolute",
            top: "5px",
            right: "2px",
            padding: "5px",
            fontSize: 27,
            cursor: "pointer",
            color: "red",
            marginLeft: "10px",
          }}
        />
      )} */}
    </Stack>
  );
};

const renderConfirmButton = (
  currentBookings,
  handleConfirmBooking,
  bookingError,
  handleCloseErrorClick
) => {
  const renderError = () => {
    return (
      !!bookingError && (
        <Stack
          style={{
            width: "500px",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            marginTop: "25px",
            marginLeft: "25px",
            border: "1px solid #E6E6E6",
            padding: "0 25px",
          }}
        >
          <p
            style={{
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: 12,
              textTransform: "uppercase",
            }}
          >
            {bookingError}
          </p>
          <CloseOutlined
            style={{ cursor: "pointer", marginTop: "10px" }}
            onClick={handleCloseErrorClick}
          />
        </Stack>
      )
    );
  };
  return (
    <>
      <CustomButton
        label="Submit"
        smallButton
        filled
        bookingSubmit={currentBookings.length}
        blackText
        onClick={handleConfirmBooking}
        disabled={!currentBookings.length}
        style={{
          marginTop: "25px",
          padding: "17px 0",
          width: "100px",
          borderColor: "#7CAF00",
        }}
      />
      {renderError()}
    </>
  );
};

const renderCurrentLocationBar = (
  currentLocation,
  nrOfLocations,
  setCurrentSelectedDate,
  handlePrevLocation,
  handleNextLocation,
  locations,
  setCurrentLocation,
  setMeetingType,
  handleCloseErrorClick,
  mdUp,
  handleUnselect
) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      marginBottom={2}
    >
      <Stack style={{ marginBottom: "5px" }}>
        <p
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: !mdUp ? 11 : 16,
            textTransform: "uppercase",
          }}
        >
          Location
        </p>
      </Stack>
      <CustomSelect
        width="225px"
        value={currentLocation}
        onChange={(e) => {
          setCurrentLocation(e.target.value);
          handleUnselect();
          setMeetingType("Showroom");
          handleCloseErrorClick();
        }}
      >
        {locations.map((location) => (
          <MenuItem key={location.name} value={location}>
            {location.name}
          </MenuItem>
        ))}
      </CustomSelect>
      <div style={{ width: "100px" }} />
    </Stack>
  );
};

const renderBookingTimeBanner = () => {
  return (
    <Item
      style={{
        color: "black",
        padding: "5px",
        marginTop: "15px",
        border: "none",
        boxShadow: "none",
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <img
          src={tmwWarningIcon}
          style={{ width: "20px", marginRight: "10px" }}
        />
        <span
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 2,
            fontSize: 10,
            textTransform: "uppercase",
          }}
        >
          Bookings take 60 minutes
        </span>
      </span>
    </Item>
  );
};

const renderCurrentBookings = (
  currentBookings,
  currentLocation,
  setCurrentBookings,
  mdUp
) => {
  if (
    !currentBookings.filter(
      (booking) => booking.location === currentLocation.name
    ).length
  )
    return null;
  return (
    <Stack
      style={{
        position: "relative",
        marginTop: "10px",
        marginBottom: "-10px",
        border: "1px solid #E6E6E6",
      }}
    >
      {currentBookings
        .filter((booking) => booking.location === currentLocation.name)
        .map((thisLocationBooking) => {
          return (
            <Stack key="current_booking" style={{ padding: "5px 0" }}>
              <p
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 3,
                  fontSize: !mdUp ? 10 : 12,
                  textTransform: "uppercase",
                }}
              >
                Current booking
              </p>
              <BookingItem style={{ position: "relative" }}>
                {moment(thisLocationBooking.date).format("DD/MM/yyyy")}{" "}
                {thisLocationBooking.slot}
              </BookingItem>
              <CloseIcon
                onClick={() => {
                  const filteredBookings = currentBookings.filter(
                    (booking) =>
                      booking.location !== thisLocationBooking.location
                  );
                  setCurrentBookings(filteredBookings);
                }}
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  padding: "5px",
                  fontSize: 30,
                  cursor: "pointer",
                  color: "#BEBEBE",
                  marginLeft: "10px",
                }}
              />
              {renderBookingTimeBanner()}
            </Stack>
          );
        })}
    </Stack>
  );
};

const renderOptions = (
  mdUp,
  currentLocation,
  currentBrands,
  nrOfLocations,
  handleNextLocation,
  handlePrevLocation,
  setCurrentSelectedDate,
  currentBookings,
  setCurrentBookings,
  meetingType,
  setMeetingType,
  handleConfirmBooking,
  locations,
  setCurrentLocation,
  handleCloseErrorClick,
  meetingTypeValues,
  showMeetingTypeChangeAlert,
  setShowMeetingTypeChangeAlert,
  handleUnselect
) => {
  return (
    <Stack
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: mdUp ? "25px" : "0 25px",
        width: mdUp ? "25vw" : "80vw",
        margin: mdUp ? "25px 25px 0 25px" : "-25px",
      }}
    >
      <Stack>
        {renderCurrentLocationBar(
          currentLocation,
          nrOfLocations,
          setCurrentSelectedDate,
          handlePrevLocation,
          handleNextLocation,
          locations,
          setCurrentLocation,
          setMeetingType,
          handleCloseErrorClick,
          mdUp,
          handleUnselect
        )}
      </Stack>
      {renderMeetingTypeSelect(
        meetingType,
        setMeetingType,
        currentBookings,
        currentLocation,
        mdUp,
        meetingTypeValues
      )}
      {showMeetingTypeChangeAlert &&
        renderMeetingTypeChangeAlert(setShowMeetingTypeChangeAlert, mdUp)}
      {renderCurrentBookings(
        currentBookings,
        currentLocation,
        setCurrentBookings,
        mdUp
      )}
    </Stack>
  );
};

const renderAvailableTimesList = (
  mdUp,
  visible,
  currentSelectedDate,
  setCurrentSelectedDate,
  setIsBookingModalVisible,
  handleUnselect,
  currentBrands,
  location,
  currentBookings,
  setCurrentBookings,
  calendarRef,
  meetingType,
  setShowMeetingTypeChangeAlert
) => {
  const dispatch = useDispatch();
  const params = useParams();
  const userId = params?.id;

  const { timeSlots, timezoneOffset } = useSelector(
    (state) => state.bookingProcess
  );
  const [availableTimes, setAvailableTimes] = useState([]);
  const [hoverTime, setHoverTime] = useState("");

  const timeSlotInfo = (timeSlot) => {
    if (timeSlot.availableSlots > 2) {
      return {
        enabled: true,
        background: "#ddffdd",
        border: "2px solid green",
        color: "black",
        tooltip: false,
        tooltipInfo: "",
      };
    } else if (timeSlot.availableSlots === 0) {
      return {
        enabled: false,
        background: "#FF9C89",
        border: "2px solid #bf6c49",
        color: "black",
        tooltip: true,
        tooltipInfo: "Not available",
      };
    }
    return {
      enabled: true,
      background: "#ffec9b",
      border: "2px solid #cfbc6b",
      color: "black",
      tooltip: true,
      tooltipInfo: "Limited availability, book fast!",
    };
  };

  const onSlotClick = (timeSlot, calendarRef) => {
    // Disable onclick if the slot is fully booked
    if (!timeSlotInfo(timeSlot).enabled) return;

    setCurrentBookings([
      ...currentBookings,
      {
        brands: currentBrands,
        location: location.name,
        date: currentSelectedDate,
        slot: timeSlot.time,
        meetingType,
        timezoneOffset,
      },
    ]);

    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.getEventById("selectedDate")?.remove();
    }
    setIsBookingModalVisible(false);
    setAvailableTimes([]);
    setCurrentSelectedDate("");
    setShowMeetingTypeChangeAlert(false);
  };

  const displayTimezoneOffset = () => {
    if (timezoneOffset !== null && timezoneOffset !== undefined) {
      return ` (GMT${
        timezoneOffset >= 0 ? `+${timezoneOffset}` : `-${timezoneOffset * -1}`
      })`;
    }
  };

  const getTimezoneData = (availableTimes) => {
    const userTimezoneOffset = new Date().getTimezoneOffset() / 60;

    return availableTimes.map((availableTime) => {
      const gmtTime = moment(availableTime.time, "HH:mm")
        .add(userTimezoneOffset, "h")
        .format("HH:mm");
      return { userTime: gmtTime, ...availableTime };
    });
  };

  const renderList = (calendarRef, mdUp) => {
    const locationOffset = timezoneOffset - moment().utcOffset() / 60;

    return (
      <Stack
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Stack
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
          }}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: 14,
              textTransform: "uppercase",
              marginLeft: !mdUp ? "25px" : "",
              marginRight: !mdUp ? "25px" : "",
              marginTop: !mdUp ? "25px" : "",
            }}
          >
            <span>Time</span>
            <span style={{ marginLeft: "5px" }}>{displayTimezoneOffset()}</span>
          </p>
        </Stack>
        <Stack style={{ width: "50%" }}>
          <CustomDivider fullWidth />
        </Stack>
        <Stack
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "40vh",
            width: mdUp ? "20vw" : "100%",
            padding: "0 25px",
            paddingTop: "5px",
            overflowY: "auto",
            zIndex: 8,
          }}
        >
          <Stack
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            {getTimezoneData(availableTimes).map((timeSlot, index) => {
              if (
                (currentSelectedDate ===
                  moment().add(locationOffset, "hours").format("yyyy-MM-DD") &&
                  moment().add(locationOffset, "hours").format("HH:mm") <
                    timeSlot.time) ||
                currentSelectedDate !==
                  moment().add(locationOffset, "hours").format("yyyy-MM-DD")
              )
                return (
                  <Stack
                    onClick={() => onSlotClick(timeSlot, calendarRef)}
                    onMouseEnter={() => setHoverTime(timeSlot.time)}
                    onMouseLeave={() => setHoverTime("")}
                    style={{
                      width: "75%",
                      transition: "all 0.15s ease-in-out",
                      position: "relative",
                      cursor: timeSlotInfo(timeSlot).enabled ? "pointer" : "",
                      userSelect: "none",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: timeSlotInfo(timeSlot).enabled
                        ? "black"
                        : "#E6E6E6",
                    }}
                    key={timeSlot.time}
                    id="booking_time_selection"
                  >
                    <span
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        fontFamily: "tmwCustomFont",
                        letterSpacing: 3,
                        fontSize: 18,
                        textTransform: "uppercase",
                        gap: "25px",
                        marginTop: "-10px",
                        width: "100%",
                      }}
                      id="booking_time_selection_span"
                    >
                      <p id="booking_time_selection_p">{timeSlot.time}</p>
                      {timeSlotInfo(timeSlot).tooltip && (
                        <CustomTooltip
                          title={timeSlotInfo(timeSlot).tooltipInfo}
                        >
                          <img
                            id="booking_tooltip_img"
                            src={tmwWarningIcon}
                            style={{
                              width: "20px",
                              position: "absolute",
                              left: "70%",
                            }}
                          />
                        </CustomTooltip>
                      )}
                    </span>
                    {index + 1 !== availableTimes.length && (
                      <Stack style={{ width: "78%" }}>
                        <CustomDivider fullWidth />
                      </Stack>
                    )}
                  </Stack>
                );
            })}
          </Stack>
        </Stack>
      </Stack>
    );
  };

  // Fetch times from bookings when the selected date changes
  useEffect(() => {
    if (visible === true) {
      dispatch(
        thunks.getTimeSlots(userId, {
          brands: currentBrands.map((brand) => brand.brand_name),
          location: location.name,
          date: currentSelectedDate,
        })
      );
    }
  }, [currentSelectedDate]);

  useEffect(() => {
    if (location && currentSelectedDate) {
      dispatch(
        thunks.getTimezoneOffset(userId, location.name, currentSelectedDate)
      );
    }
  }, [currentSelectedDate]);

  useEffect(() => {
    if (timeSlots?.length) {
      setAvailableTimes(timeSlots);
    }
  }, [timeSlots]);

  if (visible) {
    return mdUp ? (
      renderList(calendarRef, mdUp)
    ) : (
      <Dialog
        open={visible}
        onClose={handleUnselect}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack>{renderList(calendarRef)}</Stack>
      </Dialog>
    );
  }
};

export const CalendarComponent = ({
  nrOfLocations,
  location,
  handleNextLocation,
  handlePrevLocation,
  currentBrands,
  currentBookings,
  setCurrentBookings,
  meetingType,
  setMeetingType,
  handleConfirmBooking,
  locations,
  setCurrentLocation,
  meetingTypeValues,
  setMeetingTypeValues,
  showMeetingTypeChangeAlert,
  setShowMeetingTypeChangeAlert,
}) => {
  const params = useParams();
  const userId = params?.id;
  const dispatch = useDispatch();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mobileView = !mdUp;
  const [date, setDate] = useState(new Date());
  const [currentSelectedDate, setCurrentSelectedDate] = useState("");
  const [isBookingModalVisible, setIsBookingModalVisible] = useState(false);

  const { bookingError, allTimezones } = useSelector(
    (state) => state.bookingProcess
  );

  useEffect(() => {
    dispatch(thunks.getAllTimezones(userId));
  }, []);

  // Get the timezone differnece of location relative to the client location
  const locationOffset = allTimezones[location.name];
  const timezoneOffset = locationOffset - moment().utcOffset() / 60;

  const calendarRef = useRef(null);

  const handleDatePrev = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  }, []);

  const handleDateNext = useCallback(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  }, []);

  const unselectCurrentDay = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.unselect();
    }
  };

  const handleOpenTimeSelect = (e) => {
    if (e?.dayEl?.classList?.value.includes("fc-day-other")) {
      return;
    }

    const availabilityDates = [];
    location?.availability.map((date) => {
      availabilityDates.push(date.day);
    });

    // If the selected date falls outside of the array don't allow it to be selected
    if (!availabilityDates.includes(e.dateStr)) {
      handleUnselect();
      setIsBookingModalVisible(false);
      return;
    }

    // Check if the user has already made a booking for this location and if yes don't allow him to book
    const isBooked = currentBookings.filter(
      (booking) => booking.location === location.name
    ).length;
    if (isBooked) return;

    const selectedDayInfo = location?.availability.find((date) => {
      return date.day === e.dateStr;
    });

    if (selectedDayInfo) {
      if (
        JSON.stringify(selectedDayInfo.meetingTypes) !==
        JSON.stringify(meetingTypeValues)
      ) {
        setShowMeetingTypeChangeAlert(true);
      } else {
        setShowMeetingTypeChangeAlert(false);
      }
      setMeetingTypeValues(selectedDayInfo.meetingTypes);
    }
    setCurrentSelectedDate(e.dateStr);
    setIsBookingModalVisible(true);
  };

  const handleUnselect = (e) => {
    if (
      e?.jsEvent?.target?.id !== "booking_time_selection" &&
      e?.jsEvent?.target?.id !== "booking_time_selection_span" &&
      e?.jsEvent?.target?.id !== "booking_time_selection_p" &&
      e?.jsEvent?.target?.id !== "booking_tooltip_img" &&
      e?.jsEvent?.target?.id !== "meeting_type_select"
    ) {
      const calendarEl = calendarRef.current;
      if (calendarEl) {
        const calendarApi = calendarEl.getApi();
        calendarApi.getEventById("selectedDate")?.remove();
      }
      setCurrentSelectedDate("");
      setIsBookingModalVisible(false);
      unselectCurrentDay();
    }
  };

  // Close modal on esc key press
  const handleEscKey = useCallback(
    (event) => {
      setCurrentSelectedDate("");
      setIsBookingModalVisible(false);
      unselectCurrentDay();
    },
    [setIsBookingModalVisible]
  );

  const handleCloseErrorClick = () => {
    dispatch(slice.actions.setBookingError(""));
  };

  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);

    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);

  useEffect(() => {
    const calendarEl = calendarRef.current;
    if (calendarEl && currentSelectedDate) {
      const calendarApi = calendarEl.getApi();

      calendarApi.getEventById("selectedDate")?.remove();

      calendarApi.addEvent({
        id: "selectedDate",
        start: currentSelectedDate,
      });
    }
  }, [currentSelectedDate]);

  return (
    <Container maxWidth="xxl">
      <Stack
        style={{
          display: "flex",
          flexDirection: mdUp ? "row" : "column",
          alignItems: mdUp ? "flex-start" : "center",
          justifyContent: mdUp ? "flex-start" : "center",
        }}
      >
        {mdUp &&
          renderOptions(
            mdUp,
            location,
            currentBrands,
            nrOfLocations,
            handleNextLocation,
            handlePrevLocation,
            setCurrentSelectedDate,
            currentBookings,
            setCurrentBookings,
            meetingType,
            setMeetingType,
            handleConfirmBooking,
            locations,
            setCurrentLocation,
            handleCloseErrorClick,
            meetingTypeValues,
            showMeetingTypeChangeAlert,
            setShowMeetingTypeChangeAlert,
            handleUnselect
          )}
        <Stack
          style={{
            width: mdUp ? "40vw" : "100%",
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CalendarToolbar
            startingDate={new Date(location?.availability[0].day)}
            date={date}
            onDateNext={handleDateNext}
            onDatePrev={handleDatePrev}
            location={location}
            nrOfLocations={nrOfLocations}
            handleNextLocation={handleNextLocation}
            handlePrevLocation={handlePrevLocation}
          />
          <CalendarContainer
            sx={{
              width: mobileView ? "350px" : "500px",
              // Set dates outside the month bound same color as the ones inside the month
              [moment(location?.availability[0].day).isAfter(
                moment(moment().add(timezoneOffset, "hours")).format(
                  "yyyy-MM-DD"
                )
              ) &&
              ".fc td.fc-day.fc-day-today  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner"]:
                {
                  opacity: "20%",
                },
              [moment(location?.availability[0].day) ===
                moment().add(timezoneOffset, "hours").format("yyyy-MM-DD") &&
              ".fc td.fc-day.fc-day-today"]: { opacity: "20%" },
              ".fc td.fc-day.fc-day-past.fc-day-other": {
                opacity: "0%",
                cursor: "default !important",
              },
              ".fc td.fc-day.fc-day-past  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top":
                {
                  opacity: "20%",
                },
              ".fc td.fc-day.fc-day-future.fc-day-other": {
                opacity: "0%",
                cursor: "default !important",
              },
              ".fc td.fc-day.fc-day-future  .fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top":
                {
                  opacity: "20%",
                },
              ...Object.assign(
                {},
                ...location.availability.map((date) => ({
                  [`td[data-date="${date.day}"] .fc-daygrid-day-frame.fc-scrollgrid-sync-inner`]:
                    {
                      opacity: "100% !important",
                      cursor: "pointer",
                    },
                  [`td[data-date="${date.day}"] .fc-daygrid-day-frame.fc-scrollgrid-sync-inner  .fc-daygrid-day-top`]:
                    {
                      opacity: "100% !important",
                    },
                  [`td[data-date="${date.day}"].fc-day-other .fc-daygrid-day-frame.fc-scrollgrid-sync-inner`]:
                    {
                      opacity: "0% !important",
                      cursor: "default !important",
                    },
                }))
              ),
            }}
          >
            <FullCalendar
              dayHeaderFormat={{ weekday: "narrow" }}
              allDayMaintainDuration
              dayMaxEventRows={3}
              eventDisplay="block"
              eventResizableFromStart
              headerToolbar={false}
              height={mobileView ? 450 : 450}
              aspectRatio={0.75}
              firstDay={1}
              initialDate={moment(location?.availability[0].day)
                .add(timezoneOffset, "hours")
                .format("yyyy-MM-DD")}
              selectConstraint={{
                start: moment(moment().add(timezoneOffset, "hours")).isAfter(
                  moment(location?.availability[0].day).add(
                    timezoneOffset,
                    "hours"
                  )
                )
                  ? moment(moment().add(timezoneOffset, "hours")).subtract(
                      1,
                      "day"
                    )
                  : moment(location?.availability[0].day).subtract(1, "day"),
                end: moment(location?.availability.slice(-1)[0].day).add(
                  1,
                  "day"
                ),
              }}
              plugins={[
                dayGridPlugin,
                interactionPlugin,
                listPlugin,
                timeGridPlugin,
              ]}
              ref={calendarRef}
              rerenderDelay={10}
              weekends
              selectable
              unselect={handleUnselect}
              dateClick={handleOpenTimeSelect}
            />
          </CalendarContainer>
        </Stack>
        {!mdUp &&
          renderOptions(
            mdUp,
            location,
            currentBrands,
            nrOfLocations,
            handleNextLocation,
            handlePrevLocation,
            setCurrentSelectedDate,
            currentBookings,
            setCurrentBookings,
            meetingType,
            setMeetingType,
            handleConfirmBooking,
            locations,
            setCurrentLocation,
            handleCloseErrorClick,
            meetingTypeValues,
            showMeetingTypeChangeAlert,
            setShowMeetingTypeChangeAlert,
            handleUnselect
          )}
        <Stack
          style={{
            position: "sticky",
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {renderAvailableTimesList(
            mdUp,
            isBookingModalVisible,
            currentSelectedDate,
            setCurrentSelectedDate,
            setIsBookingModalVisible,
            handleUnselect,
            currentBrands,
            location,
            currentBookings,
            setCurrentBookings,
            calendarRef,
            meetingType,
            setShowMeetingTypeChangeAlert
          )}
        </Stack>
      </Stack>
      <Stack
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: mobileView && "50px",
          marginBottom: "5px",
        }}
      >
        <div
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: !mdUp ? 10 : 12,
            textTransform: "uppercase",
            marginTop: mobileView ? "50px" : "5px",
          }}
        >
          Your brands selection
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "tmwCustomFont",
            letterSpacing: 4,
            fontSize: !mdUp ? 14 : 20,
            textTransform: "uppercase",
            width: mobileView ? "90%" : "800px",
            wordBreak: "normal",
            lineHeight: "50px",
            marginTop: "5px",
          }}
        >
          {currentBrands.map((brand, index) => {
            if (index !== currentBrands.length - 1) {
              return `${brand.brand_name}, `;
            }
            return brand.brand_name;
          })}
        </div>
      </Stack>
      <Stack
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "150px",
        }}
      >
        <Stack
          style={{ width: "150px", display: "flex", alignItems: "center" }}
        >
          {renderConfirmButton(
            currentBookings,
            handleConfirmBooking,
            bookingError,
            handleCloseErrorClick
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export const NotesField = ({ value, handleChange, style }) => {
  return (
    <CustomTextField
      label="Notes"
      name="Notes"
      value={value}
      handleChange={handleChange}
      style={style}
      textFieldStyle={{
        padding: "6px 0",
      }}
      multiline
    />
  );
};
