import PropTypes from "prop-types";
import { Box, Container, Stack, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Logo } from "src/components/logo";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import tmwLoginBg from "src/images/tmwLoginBg.jpg";
import tmwLogo from "src/images/tmw_logo_white.svg";

const TOP_NAV_HEIGHT = 64;

const LayoutRoot = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top center",
  backgroundImage: 'url("/assets/gradient-bg.svg")',
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  height: "100%",
}));

export const Layout = (props) => {
  const { children } = props;
  const mdUp = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: mdUp ? "column" : "row",
        alignItems: "center",
        justifyContent: "flex-start",
        flex: "1 1 auto",
        backgroundImage: `url(${tmwLoginBg})`,
        backgroundPositionX: "center",
        backgroundPositionY: "25%",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        paddingRight: !mdUp && "100px",
      }}
    >
      <Container
        maxWidth="sm"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "25vh",
        }}
      >
        <img
          style={{
            width: mdUp ? "75%" : "500px",
          }}
          src={tmwLogo}
        />
      </Container>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          py: !mdUp && {
            xs: "60px",
            md: "120px",
          },
        }}
      >
        {children}
      </Container>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
