import { useState, useEffect } from "react";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { Seo } from "src/components/seo";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "src/store";
import { thunks } from "src/thunks/booking-process";
import { useRouter } from "src/hooks/use-router";
// Guard
import { withUserIsInDBRoleGuard } from "src/hocs/with-role-based-guard";
// Components
import { NotesField, renderLogo } from "./booking-process-components";
import {
  CustomButton,
  CustomDivider,
  CustomTypography,
} from "src/components/inputs";
import { LoaderIcon } from "react-hot-toast";

const BrandBookingSelect = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const salesCampaignId = params?.sc_id;
  const userId = params?.id;
  const router = useRouter();
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mobileView = !mdUp;
  const screenHeight = useMediaQuery("(min-height:1050px)");

  const {
    activeBrands,
    activeBrandsLoading,
    targetBrands,
    targetBrandsLoading,
    storeName,
    hubspotError,
    bookingLinks,
  } = useSelector((state) => state.bookingProcess);

  const [selectedActiveBrands, setSelectedActiveBrands] = useState([]);
  const [selectedTargetBrands, setSelectedTargetBrands] = useState([]);

  const [activeHoveredBrand, setActiveHoveredBrand] = useState("");
  const [targetHoveredBrand, setTargetHoveredBrand] = useState("");
  const [hoveredLink, setHoveredLink] = useState(0);

  const [notes, setNotes] = useState("");

  useEffect(() => {
    dispatch(thunks.getActiveAndTargetBrands(userId, salesCampaignId));
    dispatch(thunks.getCusNumbers(userId, salesCampaignId));
    dispatch(thunks.getCategories(userId, salesCampaignId));
    dispatch(thunks.getBookingLinks(userId));
  }, []);

  useEffect(() => {
    setSelectedActiveBrands(activeBrands);
    setSelectedTargetBrands(targetBrands);
  }, [activeBrands, targetBrands]);

  const handleSubmitSelectedBrands = () => {
    const chosenSelectedActiveBrands = selectedActiveBrands.filter(
      (brand) => brand.selected
    );
    const chosenSelectedTargetBrands = selectedTargetBrands.filter(
      (brand) => brand.selected
    );

    const selectedBrands = [
      ...chosenSelectedActiveBrands,
      ...chosenSelectedTargetBrands,
    ];

    if (!selectedBrands.length) {
      return;
    }

    dispatch(thunks.setSelectedBrands(selectedBrands));
    dispatch(thunks.setNotes(notes));

    // Find out the active brands that got deselected

    // Later save these brands until the booking completion stage and then send them
    // to the backend to set the deal status to the relevant deal to SKIPPED

    const secondBrandNames = selectedBrands.map((item) => item.brandName);
    const uniqueBrands = activeBrands.filter(
      (item) => !secondBrandNames.includes(item.brandName)
    );

    const skippedBrandNames = uniqueBrands.map((item) => {
      return item.brandName;
    });

    // Store the skipped active brands
    dispatch(thunks.setSkippedBrands(skippedBrandNames));

    router.push("calendar");
  };

  // COMPONENTS
  const Item = styled(Paper)(() => ({
    fontFamily: "tmwCustomFont",
    letterSpacing: 4,
    fontSize: mobileView ? 15 : 24,
    textAlign: "center",
    boxShadow: "none",
  }));

  const toggleBrandSelection = (
    event,
    brandName,
    selectedBrands,
    setSelectedBrands
  ) => {
    event.stopPropagation();
    const updatedBrands = selectedBrands.map((brand) => {
      if (brand.brandName === brandName) {
        return {
          ...brand,
          selected: !brand.selected, // Update the selected state to true
        };
      }
      return brand; // Keep other brands unchanged
    });

    setSelectedBrands(updatedBrands);
  };

  const renderBrands = (selectedBrands, setSelectedBrands, brandType) => {
    if (!selectedBrands || !selectedBrands.length) return null;

    const brandComponents = selectedBrands.map((brand, index) => (
      <Item
        style={{
          cursor: "pointer",
          userSelect: "none",
          lineHeight: "30px",
          color:
            ((brandType === "active" &&
              activeHoveredBrand === brand.brandName) ||
              (brandType === "target" &&
                targetHoveredBrand === brand.brandName)) &&
            brand.selected &&
            !mobileView
              ? "#BDCB92"
              : ((brandType === "active" &&
                  activeHoveredBrand === brand.brandName) ||
                  (brandType === "target" &&
                    targetHoveredBrand === brand.brandName)) &&
                !brand.selected &&
                !mobileView
              ? "#B7B7B7"
              : brand.selected
              ? "#7CAF00"
              : "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        key={brand.id}
        onClick={(e) =>
          toggleBrandSelection(
            e,
            brand.brandName,
            selectedBrands,
            setSelectedBrands
          )
        }
        onMouseEnter={() =>
          brandType === "active"
            ? setActiveHoveredBrand(brand.brandName)
            : setTargetHoveredBrand(brand.brandName)
        }
        onMouseLeave={() =>
          brandType === "active"
            ? setActiveHoveredBrand("")
            : setTargetHoveredBrand("")
        }
      >
        <span style={{ fontSize: 20 }}>{brand.brandName}</span>
        {selectedBrands.length !== index + 1 && ","}
      </Item>
    ));

    return (
      <Stack
        style={{
          width: "120%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          columnGap: 20,
          rowGap: 10,
          flexWrap: "wrap",
          padding: "10px",
        }}
      >
        {brandComponents}
      </Stack>
    );
  };

  const renderBrandSelection = () => {
    let chosenActive = true;
    let chosenTarget = true;

    selectedActiveBrands.map((brand) => {
      if (brand.selected === true) {
        chosenActive = false;
      }
    });

    selectedTargetBrands.map((brand) => {
      if (brand.selected === true) {
        chosenTarget = false;
      }
    });

    return (
      <>
        <p
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: mobileView ? 10 : 11,
            textTransform: "uppercase",
            marginBottom: screenHeight ? "15px" : "12px",
            color: "black",
          }}
        >
          FOR YOUR EXISTING BRANDS
        </p>
        {renderBrands(selectedActiveBrands, setSelectedActiveBrands, "active")}
        {!!selectedTargetBrands?.length && (
          <p
            style={{
              fontFamily: "tmwCustomFont",
              letterSpacing: 3,
              fontSize: mobileView ? 10 : 11,
              textTransform: "uppercase",
              margin: screenHeight ? "20px 0 15px 0" : "24px 0 12px 0",
              textAlign: "center",
              color: "black",
            }}
          >
            YOU MAY ALSO LIKE TO SELECT NEW BRANDS TO REVIEW
          </p>
        )}
        {renderBrands(selectedTargetBrands, setSelectedTargetBrands, "target")}
      </>
    );
  };

  const renderLinks = () => {
    return (
      <>
        <p
          style={{
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: mobileView ? 7 : 9,
            textAlign: "center",
            lineHeight: "200%",
            textTransform: "uppercase",
            marginTop: mobileView ? "50px" : screenHeight ? "30px" : "36px",
            marginBottom: screenHeight ? "" : "0px",
            width: mobileView ? "90%" : "800px",
          }}
        >
          Please review Tomorrow Brand Portfolio Presentation and Showroom
          Flyers at the links below for specific details on dates and locations
          for each brand.
        </p>
        <p
          style={{
            display: "flex",
            flexDirection: "column",
            fontFamily: "tmwCustomFont",
            letterSpacing: 3,
            fontSize: mobileView ? 7 : 10,
            textAlign: "center",
            textTransform: "uppercase",
            marginTop: screenHeight ? "25px" : "12px",
            marginBottom: screenHeight ? "15px" : "24px",
            gap: "7px",
          }}
        >
          {bookingLinks?.brandPortfolioLink && (
            <a
              href={bookingLinks.brandPortfolioLink}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => setHoveredLink(1)}
              onMouseLeave={() => setHoveredLink(0)}
              style={{ color: hoveredLink === 1 ? "#BEBEBE" : "black" }}
            >
              BRAND PORTFOLIO
            </a>
          )}
          {bookingLinks?.showroomFliersLink && (
            <a
              href={bookingLinks.showroomFliersLink}
              target="_blank"
              rel="noreferrer"
              onMouseEnter={() => setHoveredLink(2)}
              onMouseLeave={() => setHoveredLink(0)}
              style={{ color: hoveredLink === 2 ? "#BEBEBE" : "black" }}
            >
              SHOWROOM FLYERS
            </a>
          )}
        </p>
      </>
    );
  };

  const renderSubmitButton = () => {
    let disabledFlag = true;

    selectedActiveBrands.map((brand) => {
      if (brand.selected === true) {
        disabledFlag = false;
      }
    });

    selectedTargetBrands.map((brand) => {
      if (brand.selected === true) {
        disabledFlag = false;
      }
    });

    return (
      <CustomButton
        label="Next"
        onClick={handleSubmitSelectedBrands}
        smallButton
        filled
        bookingSubmit={!disabledFlag}
        blackText
        style={{
          marginTop: mobileView ? "5px" : screenHeight ? "20px" : "24px",
          padding: "17px 0",
          width: "100px",
          borderColor: "#7CAF00",
        }}
        disabled={disabledFlag}
      />
    );
  };

  const renderNotesField = () => {
    return (
      <Stack style={{ marginBottom: mobileView && "25px" }}>
        <NotesField
          value={notes}
          handleChange={(e) => setNotes(e.target.value)}
          style={{ width: mobileView ? "200px" : "300px" }}
        />
      </Stack>
    );
  };

  return (
    <>
      <Seo title="Booking: Brand Select" />
      <span
        style={{
          marginTop: mobileView ? "-15px" : "",
          marginLeft: mobileView ? "-15px" : "",
          marginBottom: mobileView ? "15px" : "",
        }}
      >
        {renderLogo(mobileView)}
      </span>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          padding: mobileView ? "50px 0 100px 0" : "0 0 25px 0",
        }}
      >
        <Stack
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: 20,
          }}
        >
          <p
            style={{
              fontFamily: "tmwCustomFont",
              letterSpacing: 4,
              fontSize: mobileView ? 18 : 20,
              margin: mobileView
                ? "-75px 0 0 0"
                : screenHeight
                ? "-100px 0 0 0"
                : "-106px 0 0 0",
            }}
          >
            {storeName ? `HELLO, ${storeName}` : "HELLO"}
          </p>
          {(activeBrandsLoading ||
            targetBrandsLoading ||
            !!selectedActiveBrands?.length) && (
            <>
              <p
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 3,
                  fontSize: mobileView ? 10 : 12,
                  textTransform: "uppercase",
                  textAlign: "center",
                  marginBottom: screenHeight ? "35px" : "24px",
                }}
              >
                Please book your appointment
              </p>
              <Stack
                style={{
                  width: "100%",
                  marginBottom: screenHeight ? "20px" : "24px",
                }}
              >
                <CustomDivider />
              </Stack>
            </>
          )}
          {!activeBrandsLoading &&
            !targetBrandsLoading &&
            !selectedActiveBrands?.length &&
            !selectedTargetBrands?.length && (
              <p
                style={{
                  fontFamily: "tmwCustomFont",
                  letterSpacing: 3,
                  fontSize: mobileView ? 10 : 10,
                  textTransform: "uppercase",
                  marginBottom: "35px",
                }}
              >
                No brands available
              </p>
            )}
          {!hubspotError ? (
            activeBrandsLoading || targetBrandsLoading ? (
              <LoaderIcon
                style={{ width: "22px", height: "22px", marginTop: "30px" }}
              />
            ) : (
              (selectedActiveBrands?.length !== 0 ||
                selectedTargetBrands?.length !== 0) && (
                <Stack
                  style={{
                    width: "75%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {renderBrandSelection()}
                  {renderLinks()}
                  {renderNotesField()}
                  {renderSubmitButton()}
                </Stack>
              )
            )
          ) : (
            <Stack
              style={{
                width: mdUp ? "50%" : "75%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40vh",
              }}
            >
              <CustomTypography
                message={hubspotError}
                style={{ fontSize: 14, textAlign: "center" }}
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default withUserIsInDBRoleGuard(BrandBookingSelect);
