import axios from "axios";
const deployEnvironment = require("../../deployEnvironment.js");

const API = deployEnvironment;
const STORAGE_KEY = "accessToken";

class ProductAttributesApi {
  async getLinesheetList() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetsResponse = await axios.get(
      `${API}/api/linesheets/list/product-attributes`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheets = getLinesheetsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheets) {
          reject(new Error("Unauthorized"));
          return;
        }
        resolve(linesheets);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Linesheet Products List
  async getLinesheetProductsList(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getLinesheetProductsResponse = await axios.get(
      `${API}/api/product-attributes/list/${linesheetId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetProducts = getLinesheetProductsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetProducts) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetProducts);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  // Update Linesheet Products (Bulk)
  async updateLinesheetProducts(linesheetId, data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);
    const linesheetProductsBody = data;

    const updateLinesheetProductsResponse = await axios.post(
      `${API}/api/product-attributes/${linesheetId}/linesheet-products/manage`,
      linesheetProductsBody,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const linesheetProducts = updateLinesheetProductsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!linesheetProducts) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(linesheetProducts);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async uploadLinesheetProductsFromExcel(linesheetId, formData) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    try {
      const excelImportDataResponse = await axios.post(
        `${API}/api/product-attributes/${linesheetId}/linesheet-products/import/excel`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      return excelImportDataResponse.data;
    } catch (err) {
      throw err.response.data;
    }
  }

  async exportLinesheetProductsExcel(linesheetId) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const exportLinesheetProductsResponse = await axios.get(
      `${API}/api/product-attributes/${linesheetId}/linesheet-products/export/csv-or-excel`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const exportLinesheetProductsData = exportLinesheetProductsResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!exportLinesheetProductsData) {
          reject(new Error("Unauthorized"));
          return;
        }

        resolve(exportLinesheetProductsData);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async getManufacturers() {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const getManufacturersResponse = await axios.get(
      `${API}/api/product-attributes/manufacturers`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const manufacturers = getManufacturersResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!manufacturers) {
          reject(new Error("Unauthorized"));
          return;
        }
        resolve(manufacturers);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }

  async createManufacturer(data) {
    const accessToken = sessionStorage.getItem(STORAGE_KEY);

    const createManufacturerResponse = await axios.post(
      `${API}/api/manufacturers/create`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const manufacturer = createManufacturerResponse.data;

    return new Promise((resolve, reject) => {
      try {
        if (!manufacturer) {
          reject(new Error("Unauthorized"));
          return;
        }
        resolve(manufacturer);
      } catch (err) {
        reject(new Error("Internal server error"));
      }
    });
  }
}

export const productAttributesApi = new ProductAttributesApi();
