import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Error404Page from "src/pages/404";

const Index = lazy(() => import("src/pages/customers"));
const CompanySelect = lazy(() =>
  import("src/pages/dashboard/company/forms/company-select")
);
const CompanyForm = lazy(() =>
  import("src/pages/dashboard/company/forms/company-form")
);

export const customersRoutes = [
  {
    path: "customers",
    children: [
      {
        index: true,
        element: <Navigate to="/" />,
      },
      {
        path: "/customers/dashboard/:company_hash",
        element: <Index />,
      },
      {
        path: "dashboard",
        element: <Navigate to="/" />,
      },
      {
        path: "/customers/company-select",
        element: <CompanySelect />,
      },
      {
        path: "/customers/company-form/:company_hash",
        element: <CompanyForm />,
      },
    ],
  },
];
