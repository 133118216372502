import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";
import { Issuer } from "src/utils/auth";
import { useAuth } from "src/hooks/use-auth";
import { paths } from "src/paths";
import { useRouter } from "src/hooks/use-router";
import toast from "react-hot-toast";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import { Avatar, Box, ButtonBase, SvgIcon } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";
import { AccountPopover } from "./account-popover";
import ContactAvatar from "src/icons/tmw-ui/Tomorrow-Portal-User-Symbol.png";
import { LogoutConfirmModal } from "../modals/logout-confirm-modal";

export const AccountButton = () => {
  const popover = usePopover();
  const auth = useAuth();
  const router = useRouter();
  const { t } = useTranslation();

  const [logoutModalShow, setLogoutModalShow] = useState(false);

  const handleLogout = useCallback(async () => {
    try {
      popover.handleClose?.();

      switch (auth.issuer) {
        case Issuer.Amplify: {
          await auth.signOut();
          break;
        }

        case Issuer.Auth0: {
          await auth.logout();
          break;
        }

        case Issuer.Firebase: {
          await auth.signOut();
          break;
        }

        case Issuer.JWT: {
          await auth.signOut();
          break;
        }

        default: {
          console.warn("Using an unknown Auth Issuer, did not log out");
        }
      }

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router, popover]);

  const renderLogoutModal = () => {
    return (
      <>
        {logoutModalShow && (
          <LogoutConfirmModal
            title={t(tokens.topNav.logout.title)}
            message={t(tokens.topNav.logout.title)}
            submitButtonLabel={t(tokens.topNav.logout.logoutButton)}
            submitButton={handleLogout}
            cancelButtonLabel={t(tokens.topNav.logout.cancelButton)}
            cancelButton={handleCloseLogoutModal}
            avatarBackgroundColor={"error.lightest"}
            avatarColor={"error.main"}
            successButtonBackgroundColor={"error.main"}
            successButtonBackgroundColorHover={"error.dark"}
            isOpen={logoutModalShow}
          />
        )}
      </>
    );
  };

  const handleLogoutClick = () => {
    popover.handleClose();
    setLogoutModalShow(true);
  };

  const handleCloseLogoutModal = () => {
    setLogoutModalShow(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
        sx={{
          alignItems: "center",
          display: "flex",
          height: 40,
          width: 40,
          borderRadius: "50%",
          background: "#fff",
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
            background: "#fff",
          }}
          src={ContactAvatar}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        onLogoutModalOpen={handleLogoutClick}
      />
      {renderLogoutModal()}
    </>
  );
};
